import {selectCartItemById} from '../../utils/select-cart-item';
import {DELIVERIES, DELIVERY_SELECTORS, HANDLERS} from './constants';
import store from '../../store';
import {setDefaultAdditionalMessage} from './orders-cart-delivery-common';

// перед изменением/удалением учитывать документацию!
// @see docs/frontend/delivery/README.md
const initialized = [];

function initDelivery(module, slug) {
  if (initialized.includes(slug)) {
    return;
  }
  module.init();
  initialized.push(slug);
}

export function setDeliveryTemplate(slug, order) {
  const $cartItemContainer = selectCartItemById(order.id);
  const $deliveryContainer = $cartItemContainer.find(
    DELIVERY_SELECTORS[slug].container
  );

  if (!($deliveryContainer && $deliveryContainer.length)) {
    return;
  }

  const module = HANDLERS[slug];
  if (!module) {
    return;
  }
  initDelivery(module, slug);
  $deliveryContainer.html(module.getButtonTemplate(order));
}

export function initializeDeliveries(cartItemId) {
  const order = store.getOrderById(cartItemId);
  if (!order) {
    return;
  }
  const {
    company: {order_settings: orderSettings},
  } = order;
  if (!(orderSettings && orderSettings.deliveries)) {
    return;
  }

  DELIVERIES.forEach(function (slug) {
    setDeliveryTemplate(slug, order);
  });
  setDefaultAdditionalMessage(order);
}
