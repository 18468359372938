import {selectCartItemByTarget} from '../../../utils/select-cart-item';
import {
  updateDelivery as updateDeliveryRequest,
  cancelDelivery,
} from '../delivery-info-manager';
import _get from 'lodash.get';
import store from '../../../store';
import {COMMON_SELECTORS} from '../../../constants/selectors';
import {DELIVERY_SELECTORS} from '../constants';
import {LOCALES} from '../../../constants/common';

// перед изменением/удалением учитывать документацию!
// @see docs/frontend/delivery/README.md
let DELIVERY_TEMPLATES;
let _$delivery;
let _boxberry;

const getActionButtons = (withUpdateBtn) =>
  DELIVERY_TEMPLATES.deliveryActions({
    buttons: [
      {
        actionClass: DELIVERY_SELECTORS.boxberry.changeButton.replace('.', ''),
        actionText: withUpdateBtn
          ? LOCALES.boxberry.update
          : LOCALES.boxberry.change,
      },
      {
        actionClass: DELIVERY_SELECTORS.boxberry.cancelButton.replace('.', ''),
        actionText: LOCALES.boxberry.cancel,
      },
    ],
  });

const getDeliveryInfo = (order) => ({
  deliveryDescription: _get(order, 'delivery.properties.description'),
  deliveryPrice: _get(order, 'delivery.formatted_price'),
  deliveryPeriod: _get(order, 'delivery.properties.period'),
  actions: getActionButtons(),
  locales: LOCALES.delivery,
  deliveryProvider: LOCALES.boxberry.selectBoxberry,
  tip: app.i18n.delivery.selected.costTip,
});

function onUpdateFail() {
  alert('Не удалось рассчитать доставку Boxberry');
}

function updateDelivery(boxberryResponse, boxberryData) {
  const reqData = {};

  reqData.price = boxberryResponse.price;
  reqData['type_slug'] = 'boxberry';
  reqData['point_id'] = boxberryResponse.id;
  reqData.period = boxberryResponse.period;
  reqData.description =
    boxberryResponse.address + ' ' + boxberryResponse.workschedule;

  updateDeliveryRequest({
    deliveryData: reqData,
    orderId: boxberryData.orderId,
    onAfterFail: onUpdateFail,
  });
}

function getBoxberryData(order) {
  if (!order) {
    return;
  }

  function createCallback(boxberryData) {
    return function (boxberryResponse) {
      updateDelivery(boxberryResponse, boxberryData);
    };
  }

  const boxberryData = {};

  boxberryData.apiToken =
    order.company['order_settings'].deliveries.boxberry['api_key'];
  boxberryData.customCity = order.user['delivery_city_name'];
  boxberryData.weight = order.specification.weight
    ? order.specification.weight * 1000
    : 0;
  boxberryData.height = order.specification.height
    ? order.specification.height * 100
    : 0;
  boxberryData.width = order.specification.width
    ? order.specification.width * 100
    : 0;
  boxberryData.length = order.specification.length
    ? order.specification.length * 100
    : 0;
  boxberryData.orderId = order.id;
  boxberryData.callback = createCallback(boxberryData);

  return boxberryData;
}

function openBoxberryPopup(data) {
  if (!data) {
    return;
  }

  _boxberry.open(
    data.callback,
    data.apiToken,
    data.customCity,
    data.targetStart,
    data.orderSum,
    data.weight,
    data.paySum,
    data.height,
    data.width,
    data.length,
    data.prepaid
  );
}

function select() {
  if (!_boxberry) {
    return alert('Произошла ошибка, попробуйте позже.');
  }

  const $this = $(this);
  const order = store.getOrderByElement($this);

  _$delivery = $this
    .closest(COMMON_SELECTORS.ordersCartItem)
    .find(DELIVERY_SELECTORS.boxberry.container);

  openBoxberryPopup(getBoxberryData(order));
}

const getDeliveryInfoTemplate = (order) =>
  DELIVERY_TEMPLATES.deliveryInfo(getDeliveryInfo(order));

const getButtonTemplate = () => {
  if (!DELIVERY_TEMPLATES.calculateButton) {
    return '';
  }

  return DELIVERY_TEMPLATES.calculateButton({
    text: LOCALES.boxberry.selectBoxberry,
    jsClass: DELIVERY_SELECTORS.boxberry.selectButton.replace('.', ''),
  });
};

function onCancelDelivery(event) {
  cancelDelivery(selectCartItemByTarget($(event.target)).data('id'));
}

function loadBoxberry() {
  const script = document.createElement('script');
  script.onload = function () {
    _boxberry = window.boxberry;
  };
  script.async = true;
  script.src = 'https://points.boxberry.ru/js/boxberry.js?' + Date.now();
  document.body.appendChild(script);
}

function _listener() {
  $doc
    .on(
      'click',
      `${DELIVERY_SELECTORS.boxberry.selectButton}, ${DELIVERY_SELECTORS.boxberry.changeButton}`,
      select
    )
    .on('click', DELIVERY_SELECTORS.boxberry.cancelButton, onCancelDelivery)
    .on('click', '.boxberry_overlay', function () {
      _boxberry.hideContainer();
      _boxberry.hideOverlay();
    });
}

export default {
  init: () => {
    _listener();
    loadBoxberry();
    DELIVERY_TEMPLATES = store.getDeliveryTemplates();
  },
  getButtonTemplate,
  getDeliveryInfoTemplate,
  getActionButtons,
};
