// Компонент счётчика корзины

import cartPreview from './cart_preview';
import getCommonCartData from '../cart/common/get-common-cart-data';
import _get from 'lodash.get';

export default app.modules.ordersCartCounter = (function (self) {
  const SELECTORS = {
      component: '.js-orders-cart-counter',
      counter: '.js-orders-cart-counter-value',
      counterContainer: '.js-orders-cart-counter-container',
      sideMenuCounter: '.js-orders-menu-counter',
    },
    CLASS_NAMES = {
      loading: 'orders-cart-counter-loading',
    },
    EVENTS = {
      update: 'update:ordersCartCounter',
      getValue: 'getValue:ordersCartCounter',
    },
    ERROR_MESSAGE = 'Event should be emmited on counter container element.';

  function _checkTarget(target) {
    if (target === document) {
      throw new Error(ERROR_MESSAGE);
    }
  }

  const _getCounterValue = ($container) =>
    parseInt($container.find(SELECTORS.counter).text());

  function _fetchOrderCart() {
    const param = _get(app, 'config.company.companySiteCartEnabled') && {
      ['company_ids']: app.config.company.id,
    };
    return getCommonCartData(param).then(({cart}) => cart);
  }

  function _updateCounters(itemsCount, target) {
    if (target.dataset.label) {
      target.dataset.label = itemsCount;
      $(target).toggleClass('empty', itemsCount === 0);
      return;
    }

    $(target)
      .find(SELECTORS.component)
      .removeClass(CLASS_NAMES.loading)
      .end()
      .find(SELECTORS.counter)
      .text(itemsCount)
      .toggleClass('empty', itemsCount === 0);
  }

  const _getContainers = () =>
    $(SELECTORS.counterContainer + ', ' + SELECTORS.sideMenuCounter);

  function _changeValueInAllContainers($containers, itemsCount) {
    $containers.each(function () {
      _checkTarget(this);
      _updateCounters(itemsCount, this);
    });
  }

  function _onUpdateCounters(event, $containers) {
    const $counterContainers =
      $containers && $containers.length ? $containers : _getContainers();
    if (!$counterContainers.length || !app.config.currentUser.primaryProvider) {
      return;
    }

    _fetchOrderCart()
      .then(function (cart) {
        _changeValueInAllContainers(
          $counterContainers,
          _get(cart, 'items_count', 0)
        );
        cartPreview.updateCartData(cart);
      })
      .catch(function (error) {
        console.error('fetchOrderCart: error occurred', error);
      });
  }

  function _onGetCounterValue(event) {
    _checkTarget(event.target);
    _getCounterValue($(event.target));
  }

  function _initCounters() {
    const counterContainers = _getContainers();
    if (!counterContainers.length) {
      return;
    }
    counterContainers.first().trigger(EVENTS.update, [counterContainers]);
  }

  function _listener() {
    $doc
      .on(EVENTS.update, _onUpdateCounters)
      .on(EVENTS.getValue, _onGetCounterValue);
  }

  self = {
    load: _listener,
    init: _initCounters,
  };

  return self;
})(app.modules.ordersCartCounter || {});
