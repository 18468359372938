import {LOCALES} from '../../../constants/common';
import priceManager from './orders_cart_delivery_dellin_price-manager';

// перед изменением/удалением учитывать документацию!
// @see docs/frontend/delivery/README.md
const API_ROUTE = '/api/v1/dellin/calculations.json';

let _currentCompanyId = null;
let _citiesVariants = [];
let _currentCalculation = null;
let _currentDerivalAddress = null;
let _activeRecalcRequests = 0;

function setNewDerivalAddress({derival}) {
  _currentDerivalAddress = derival;
}

function setNewCitiesVariants({companyId, cities}) {
  _currentCompanyId = companyId;

  if (!cities.length) {
    return;
  }

  _citiesVariants = cities;
}

function resetState() {
  _currentCompanyId = null;
  _citiesVariants = [];
  _currentCalculation = null;
  _currentDerivalAddress = null;
}

function recalculateDelivery(facade) {
  const derival = facade.apiParams.derival;
  const arrival = facade.apiParams.arrival;
  const $currentContainer = facade.$container.closest('.js-orders-cart-item');
  const data = {
    company_id: facade.order.company.id,
    dellin: {
      derivalPoint: derival,
      arrivalPoint: arrival,
      sizedWeight: facade.order.specification.weight,
      sizedVolume: facade.order.specification.volume,
    },
  };

  _sendCalculateRequest({
    data,
    onSuccess: function (response) {
      if (--_activeRecalcRequests > 0) {
        return;
      }

      priceManager.setActiveOrderContainer({$container: $currentContainer});
      priceManager.savePrice({
        dellineResponse: response,
        arrivalPoint: arrival,
        derivalPoint: derival,
      });
    },
    onError: function (response) {
      if (--_activeRecalcRequests > 0) {
        return;
      }

      priceManager.savePrice({
        error: _getErrorText(response) || LOCALES.dellin.apiError,
      });
    },
  });

  _activeRecalcRequests++;
}

function _getErrorText(response) {
  if (
    response &&
    response.responseJSON &&
    response.responseJSON.errors &&
    response.responseJSON.errors.length
  ) {
    return response.responseJSON.errors
      .map(function (e) {
        const errors = JSON.parse(e.message);

        if (errors.messages) {
          return errors.messages.join(' ');
        }

        return Object.keys(errors)
          .map(function (key) {
            return errors[key];
          })
          .join(' ');
      })
      .join('\n');
  }
}

function _onSubmit() {
  const $this = $(this);
  const $errorMessage = $('.js-dellin-popup .js-dellin-error__message');
  const savePrice = function () {
    priceManager.savePrice({
      dellineResponse: _currentCalculation,
      arrivalPoint: _getArrivalPoint(),
      derivalPoint: _currentDerivalAddress,
    });

    _currentCalculation = null;
  };
  const onCalculate = function (calculation) {
    _currentCalculation = calculation;

    $this.html($this.data('save')).attr('disabled', false);

    $('.js-dellin-popup .js-dellin-delivery__price').html(calculation.price);
    $('.js-dellin-popup .js-dellin-price__row').removeClass('dn');
  };
  const onCalculateError = function (response) {
    const errors = _getErrorText(response) || LOCALES.dellin.apiError;

    $this.html($this.data('calculate')).attr('disabled', false);
    return $errorMessage.html(errors).removeClass('dn');
  };

  $this.html($this.data('loading')).attr('disabled', true);
  $errorMessage.html('').addClass('dn');

  return _currentCalculation
    ? savePrice()
    : _calculate(onCalculate, onCalculateError);
}

function _calculate(onSuccess, onError) {
  const derival = _currentDerivalAddress;
  const arrival = _getArrivalPoint();
  const weight = document.querySelector(
    '.js-dellin-popup .js-dellin-popup__item-weight'
  ).value;
  const volume = document.querySelector(
    '.js-dellin-popup .js-dellin-popup__item-volume'
  ).value;
  const arrivalError = document.querySelector(
    '.js-dellin-popup__empty-arrival-city-error'
  );

  arrivalError.classList.add('dn');
  if (!arrival) {
    arrivalError.classList.remove('dn');
    return onError();
  }

  _sendCalculateRequest({
    data: {
      company_id: _currentCompanyId,
      dellin: {
        derivalPoint: derival,
        arrivalPoint: arrival,
        sizedWeight: weight,
        sizedVolume: volume,
      },
    },
    onSuccess: onSuccess,
    onError: onError,
  });
}

function _getArrivalPoint() {
  const userInput = document.querySelector(
    '.js-dellin-popup .js-dellin-popup__cities-select'
  ).value;

  return (
    _citiesVariants.find(function (variant) {
      return variant.aString === userInput;
    }) || {}
  ).code;
}

function _sendCalculateRequest(facade) {
  $.ajax({
    method: 'POST',
    url: API_ROUTE,
    contentType: 'application/json;charset=utf-8',
    data: JSON.stringify(facade.data),
    success: facade.onSuccess,
    error: facade.onError,
  });
}

function _removeCurrentPrice() {
  const $submit = $('.js-dellin-popup__submit');

  _currentCalculation = null;

  $submit.html($submit.data('calculate')).attr('disabled', false);
  $('.js-dellin-popup .js-dellin-delivery__price').html('');
  $('.js-dellin-popup .js-dellin-price__row').addClass('dn');
}

function _addListeners() {
  $doc
    .on('click', '.js-dellin-popup__submit', _onSubmit)
    .on('input', '.js-dellin-popup__cities-select', _removeCurrentPrice);
}

export default {
  listeners: _addListeners,
  resetState,
  recalculateDelivery,
  setNewDerivalAddress,
  setNewCitiesVariants,
};
