// Функция получения полей АК и контактного телефона если тип комуникации с компанией "email".

import {isUserSid} from '../../../cart/utils/user-authorization-level';

/**
 * @see docs/frontend/common/communication.md
 */
export const getFieldsForEmail = ({user, delivery}) => {
  const result = {
    contacts: true,
    contactsRequired: !!delivery,
  };

  if (!isUserSid() && user.email) {
    return result;
  }

  result.auth = {
    set: ['email'],
    required: ['email'],
  };

  if (user.name) {
    return result;
  }

  result.auth.set.push('name');
  result.auth.required.push('name');
  return result;
};
