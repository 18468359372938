const addGetParamsToUrl = (url, params) => {
  if (!url) {
    return '';
  }
  if (!params) {
    return url;
  }

  Object.keys(params).forEach((key, ind) => {
    const prefix = ind === 0 ? `?` : `&`;
    let paramValue = params[key];
    if (Array.isArray(paramValue)) {
      paramValue = paramValue.join(`&${key}=`);
    }
    url +=
      paramValue || paramValue === 0 ? `${prefix}${key}=${paramValue}` : '';
  });
  return url;
};

export default addGetParamsToUrl;
