const cartURL = '/api/v1/customer/cart';

const getCartOrdersURL = (orderId) => {
  const allOrdersUrl = `/api/v1/customer/cart/orders`;
  return orderId ? allOrdersUrl + `/${orderId}` : allOrdersUrl;
};

const getPlaceCartURL = (orderId) =>
  `/api/v1/customer/cart/orders/${orderId}/place`;

const getProductItemURL = (itemId) => `/api/v1/customer/cart/items/${itemId}`;

const getUpdateUserUrl = (userId) => `/api/v1/users/${userId}`;

const getPaymentUrl = (orderId) =>
  `/api/v1/customer/orders/${orderId}/payments`;

const getActivePromoCodesCountUrl = (companyId) =>
  `/api/v1/companies/${companyId}/offer_counters/published_offer_promocodes_count`;

export default {
  cartURL,
  getCartOrdersURL,
  getPlaceCartURL,
  getProductItemURL,
  getUpdateUserUrl,
  getPaymentUrl,
  getActivePromoCodesCountUrl,
};
