export const COMMON_SELECTORS = {
  cartContainer: '.js-orders-cart-container',
  ordersCartItem: '.js-orders-cart-item',
  orderItem: '.js-orders-item',
  orderItemById: '.js-orders-item[data-id="_itemId_"]',
  ordersCartItemById: '.js-orders-cart-item[data-id="_orderId_"]',
  additionalInfo: '.js-additional-info',
  ordersCartContentArea: '.js-orders-cart-content',
  ordersCartBottomContentArea: '.js-orders-cart__bottom-content',
  ordersCartItemBottomContentArea: '.js-orders-cart-item__bottom-content',
  ordersCartCounter: '.js-orders-cart-counter-container',
  sideMenuCounter: '.js-orders-menu-counter',
  emptyContentArea: '.js-orders-cart-item__content:empty',
  sendCartItem: '.js-orders-cart-item__send',
  cartItemDelete: '.js-orders-cart-item__delete',
  cartItemTotal: '.js-orders-cart-item__total',
  cartItemJobError: '.js-orders-cart-item__job-error',
  cartItemTotalWarning: '.js-order-cart-item__total-warning',
  cartItemTotalWarningWrapper: '.js-order-cart-item__total-warning-wrapper',
  cartItemButton: '.js-orders-cart-item__button',
  onlinePaymentButton: '.js-online-payment',
  cartItemTotalBlock: '.js-orders-cart-item__total-block',
  priceTypeContainer: '.js-orders-price-type-container',
  totalProductsCart: '.js-orders-cart-item__total-product-items',
  totalProductsUnit: '.js-orders-cart-item__product-unit',
  cartItemTotalDescription: '.js-orders-cart-item__description-content',
};

export const PRODUCT_SELECTORS = {
  totalOld: '.js-orders-item__total--old',
  total: '.js-orders-item__total',
  delete: '.js-orders-item__delete',
};

export const PART_ORDER_SELECTORS = {
  companyCheckbox: '.js-company-part-order-checkbox',
  itemCheckbox: '.js-item-part-order-checkbox',
};

export const LOADER_SELECTORS = {
  component: '.js-cart-loader',
  controller: '.js-cart-loader__button',
};

export const FAST_ORDER_SELECTORS = {
  link: '.js-fast-order-pseudo-link',
  sendFastOrder: '.js-send-fast-order',
  phoneAuthField: '.js-input-phone-auth',
  phoneAuthError: '.js-error-message',
  phoneField: '.js-of-customer-phone',
  phoneError: '.js-phone-error',
  form: '.js-fast-order-form',
  clearPhone: '.js-clear-phone',
  authContainer: '.js-auth-component-container.fast-order-container',
  flashContainer: '.js-of-flash-container',
};

export const ADDITIONAL_INFO_SELECTORS = {
  errorBlock: '.js-additional-info__error-block',
  fieldGroup: '.js-additional-info__field-group',
  contactPhoneContainer: '.js-additional-info__contact-phone',
  fields: {
    phone: '.js-additional-info__phone',
    region: '.js-additional-info__regions',
    message: '.js-additional-info__message',
    segmentation: '.js-order-cart-segmentation-field',
  },
};

export const TENDER_SELECTORS = {
  createInput: '.js-orders-cart-tender__create-input',
  delayCreationInput: '.js-orders-cart__delay-tender-creation-input',
  delayCreationHint: '.js-delay-tender-creation__hint',
  delayCreationHintText: '.js-delay-tender-creation__hint-text',
};

export const SEGMENTATION_SELECTORS = {
  section: '.js-segmentation-section',
};

export const DISCOUNT_SELECTORS = {
  totalDiscount: '.js-orders-cart-item__total-discount',
  totalDiscountBlock: '.js-orders-cart-item__total-discount-block',
  cartItemTotalOld: '.js-orders-cart-item__total-old',
  totalDiscountLabel: '.js-orders-cart-item__total-discount-label',
  totalOlderPrice: '.js-orders-item__total-older-price',
};

export const QUANTITY_SELECTORS = {
  container: '.js-product-quantity__container',
  measureUnitsContainer: '.js-orders-item__measure-units-container',
  fields: {
    quantity: '.js-orders-item__count',
    measureUnits: '.js-orders-item-measure-units',
  },
  measureUnitContainer: '.js-orders-item-measure-unit-container',
  measureUnits: '.js-orders-item-measure-units',
  totalLabelRetail: '.js-orders-item__total-label-retail',
  totalLabelWholasale: '.js-orders-item__total-label-wholesale',
};

export const PROMO_CODE_SELECTORS = {
  block: '.js-promo-code',
  btn: '.js-promo-code__button',
  input: '.js-promo-code__input',
  error: '.js-promo-code__error',
};
