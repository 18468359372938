// Компонент попапа выбора способа онлайн оплаты

import getPaymentProviders from '../../src/cart/utils/get-payment-providers';
import modalTemplate from './templates/choose_online_payment_modal.handlebars';
import payOnline from '../online-payment/pay-online';
import renderPayKeeperPopup from '../online-payment/pay-keeper-popup';
import _get from 'lodash.get';

const SELECTORS = {
  payBtn: '.js-choose-online-payment-modal__pay-btn',
  input: '.js-choose-online-payment-modal__input',
  closeBtn: '.js-choose-online-payment-modal__close',
  overlay: '.js-choose-online-payment-modal',
};

let orderData, modal, onAfterSuccess;

function renderModal(order, renderResultPopup) {
  onAfterSuccess = renderResultPopup;
  modal = $(
    modalTemplate({
      title: app.config.isMobile
        ? 'Сервис онлайн-оплаты'
        : 'Выбор сервиса онлайн-оплаты',
      providers: getPaymentProviders(
        _get(order, 'company.order_settings.payments', [])
      ),
    })
  );
  $('body').append(modal);
  orderData = order;
  commonListener();
}

function openPaymentModal() {
  const checked = $(SELECTORS.input).filter((idx, input) => {
    return input.checked && input.value;
  })[0];
  if (!checked) {
    return;
  }

  if (checked.value === 'paykeeper') {
    renderPayKeeperPopup(orderData, onAfterSuccess);
  } else {
    payOnline(orderData.id, checked.value, onAfterSuccess);
  }
  closeModal();
}

function closeModal() {
  modal.remove();
}

function closeOnOverlayClick(evt) {
  evt.stopPropagation();
  evt.currentTarget === SELECTORS.overlay && closeModal();
}

function commonListener() {
  $doc
    .on('click', SELECTORS.payBtn, openPaymentModal)
    .on('click', SELECTORS.closeBtn, closeModal)
    .on('click', SELECTORS.overlay, closeOnOverlayClick);
}

export default renderModal;
