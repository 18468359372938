/*
 * При просмотре рубрик каталога 18+ показываем попап.
 * Если посетитель подтверждает совершеннолетие, оставляем его на текущей странице,
 * попап во время текущей браузерной сессии больше не показываем для всех подобный рубрик.
 * Если посетитель сообщил, что ему нет 18 лет, перенаправляем его на главную страницу портала.
 * Во время новой браузерной сессии попап появляется вновь.
 * */
import Cookies from 'js-cookie';
import {isEighteenPopup} from 'src/assets/js/shared/check-eighteen-popup/check-eighteen-popup.js';

app.modules.eighteenConfirmation = (function (self) {
  const _eighteenPopup = document.querySelector('.js-eighteen-popup');
  let _popup;

  function _changeLocationHref() {
    if (!Cookies.get('eighteenConfirmation')) {
      location.href = app.config.currentRegionHost;
    }
  }

  function _showDialog() {
    _popup = app.modules.modalDialog.create({
      content: _eighteenPopup,
      dialogClass: 'simple eighteen-confirmation-popup',
      open: function () {
        document
          .querySelector('.js-modal-dialog__overlay')
          .classList.add('o0-9');
        _eighteenPopup.classList.remove('hidden');
      },
      close: _changeLocationHref,
    });
  }

  function _init() {
    _showDialog();
  }

  function _listener() {
    const _ageConfirmationButton = _eighteenPopup.querySelector('.js-ecp-true');
    const _underageConfirmationButton =
      _eighteenPopup.querySelector('.js-ecp-false');
    _ageConfirmationButton.addEventListener(
      'click',
      _setCookieWhenClickOnAgeConfirmationButton
    );
    _underageConfirmationButton.addEventListener('click', _changeLocationHref);
  }

  function _setCookieWhenClickOnAgeConfirmationButton() {
    Cookies.set('eighteenConfirmation', 1, {
      path: '/',
      domain: '.' + app.config.shortHost,
    });
    Cookies.set('eighteenConfirmation', 1, {path: '/'});
    _popup.close();
  }

  self.load = function () {
    if (isEighteenPopup()) {
      _init();
      _listener();
    }
  };

  return self;
})(app.modules.eighteenConfirmation || {});
