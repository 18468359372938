import {COMMON_SELECTORS, PART_ORDER_SELECTORS} from '../constants/selectors';
import store from '../store';
import getCartItemsData from '../common/get-cart-items-data';
import _debounce from 'lodash.debounce';

// Компонент отправки частичного заказа

const CLASS_NAMES = {
  partOrderContainer: 'part-order-available',
};

const getSelectedOrderItemsIds = ($component) => {
  if (!$component) {
    return;
  }

  const $checkboxes = $component.find(PART_ORDER_SELECTORS.itemCheckbox),
    ids = [];

  $checkboxes.each(function (idx, checkbox) {
    checkbox.checked && ids.push(checkbox.id);
  });
  return ids;
};

const getCartItemComponent = (target) =>
  $(target).closest(COMMON_SELECTORS.ordersCartItem);

const updateAllOrderItems = (order, isSelected) =>
  order.items.map((item) => Object.assign({}, item, {selected: isSelected}));

const updateOrderItemById = (order, itemId, isSelected) =>
  order.items.map((item) =>
    item.id === Number(itemId)
      ? Object.assign({}, item, {selected: isSelected})
      : item
  );

const getUpdatedOrders = (orderId, itemsToUpdate) =>
  store
    .getOrders()
    .map((order) =>
      order.id === orderId
        ? Object.assign({}, order, {items: itemsToUpdate})
        : order
    );

const getUpdatedFlatCart = (orderId, itemsToUpdate) => {
  const flatCart = store.getFlatCart();

  return Object.assign({}, flatCart, {
    [orderId]: Object.assign({}, flatCart[orderId], {items: itemsToUpdate}),
  });
};

const updateStore = (orderId, itemsToUpdate) => {
  store.updateStore({
    orders: getUpdatedOrders(orderId, itemsToUpdate),
    flatCart: getUpdatedFlatCart(orderId, itemsToUpdate),
  });
};

const getCheckedItemsAmount = ($component) => {
  const itemCheckboxes = $component.find(PART_ORDER_SELECTORS.itemCheckbox),
    checkedItems = $.grep(itemCheckboxes, (checkbox) => checkbox.checked);

  return checkedItems && checkedItems.length;
};

const checkIfLastDisabled = ($component) => {
  const itemCheckboxes = $component[0].querySelectorAll(
      PART_ORDER_SELECTORS.itemCheckbox
    ),
    disabledItems = $.grep(itemCheckboxes, (checkbox) => checkbox.disabled);

  return disabledItems && disabledItems.length === itemCheckboxes.length;
};

const setCompanyCheckboxStatus = ($component) => {
  const $companyCheckbox = $component.find(
      PART_ORDER_SELECTORS.companyCheckbox
    ),
    $itemCheckboxes = $component.find(PART_ORDER_SELECTORS.itemCheckbox),
    orderId = $component.data('id');

  if (!$itemCheckboxes.length) {
    $companyCheckbox.parent().remove();
    $component.removeClass(CLASS_NAMES.partOrderContainer);
    return;
  }

  if (checkIfLastDisabled($component)) {
    $companyCheckbox.prop('disabled', true).prop('checked', false);
    return;
  }

  const hasCheckedItems = !!getCheckedItemsAmount($component);
  $companyCheckbox.prop('checked', hasCheckedItems);
  getCartItemsData({orderId});
};

const selectCheckbox = (evt) => {
  const target = evt.target,
    $component = getCartItemComponent(target),
    orderId = $component.data('id'),
    order = store.getOrderById(orderId),
    itemId = target.id,
    isSelected = target.checked,
    checkedItemsAmount = getCheckedItemsAmount($component),
    itemsToUpdate = updateOrderItemById(order, itemId, isSelected);

  updateStore(orderId, itemsToUpdate);
  if (checkedItemsAmount === 1 || checkedItemsAmount === 0) {
    setCompanyCheckboxStatus($component);
    return;
  }
  getCartItemsData({orderId});
};

const setItemsCheckboxStatus = ($itemCheckboxes, checked) => {
  $itemCheckboxes.each(function (idx, checkbox) {
    checkbox.checked = !checkbox.disabled && checked;
  });
};

const onCompanyCheckboxChange = (event) => {
  var target = event.target,
    $component = getCartItemComponent(target),
    $itemCheckboxes = $component.find(PART_ORDER_SELECTORS.itemCheckbox),
    checked = $(target).prop('checked'),
    orderId = $component.data('id'),
    order = store.getOrderById(orderId);

  setItemsCheckboxStatus($itemCheckboxes, checked);
  setCompanyCheckboxStatus($component);
  updateStore(orderId, updateAllOrderItems(order, checked));
};

const debouncedCheckboxSelect = _debounce(selectCheckbox, 100);
const debouncedCheckboxChange = _debounce(onCompanyCheckboxChange, 100);

function _commonListener() {
  $doc
    .on('change', PART_ORDER_SELECTORS.itemCheckbox, debouncedCheckboxSelect)
    .on(
      'change',
      PART_ORDER_SELECTORS.companyCheckbox,
      debouncedCheckboxChange
    );
}

export default {
  init: _commonListener,
  setCompanyCheckboxStatus,
  getSelectedOrderItemsIds,
};
