import {LOADER_SCOPE_NAME} from '../constants/common';
import store from '../store';
import api from '../constants/api';
import addGetParamsToUrl from '../utils/add-get-params-to-url';

function getCommonCartData(params) {
  return fetch(
    addGetParamsToUrl(
      api.cartURL,
      Object.assign(
        {},
        store.getStoreDataByScopeName(LOADER_SCOPE_NAME),
        params
      )
    )
  )
    .then((res) => res.json())
    .then((response) => response);
}

export default getCommonCartData;
