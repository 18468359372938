/**
 * Корректирует позицию попапа таким образом, что бы он был под переданным элементом и
 * не выходил за правый край окна браузера с учетом отступа справа, чтобы попап не прилипал к краю окна.
 * @param {HTMLElement} target - Элемент относительно которого позиционируем.
 * @param {HTMLElement} popup
 * @param {number} offset - Минимальный отступ от края окна справа.
 */
function correctPopupPosition({target, popup, offset = 20} = {}) {
  if (!target || target.dataset.doNotCorrectPosition || !popup) {
    return;
  }
  popup.style = 'opacity: 0 !important;';

  const {left: targetLeft} = target.getBoundingClientRect();
  const {width: popupWidth} = popup.getBoundingClientRect();
  const windowWidth = window.innerWidth;

  const currentPopupPosition = targetLeft + popupWidth + offset;
  const isInViewPort = currentPopupPosition <= windowWidth;
  if (isInViewPort) {
    popup.style = 'opacity: 1;';
    return;
  }
  const overLimit = currentPopupPosition - windowWidth;
  popup.style = `opacity: 1; left: -${overLimit}px`;
}

export default correctPopupPosition;
