import _get from 'lodash.get';

function getMinTotalMessage(data) {
  let minTotal = {};

  if (!data || data.passed) {
    return;
  }

  if (data.required) {
    minTotal.message = _getMessage(
      _get(app, 'config.ordersCart.orderMinTotalMessages.blockingMessage'),
      data
    );
    minTotal.blocked = true;
  } else {
    minTotal.message = _getMessage(
      _get(app, 'config.ordersCart.orderMinTotalMessages.infoMessage'),
      data
    );
  }

  return minTotal;
}

function _getMessage(message, data) {
  const addPrice = (
    _getPriceValue(data['min_price']) -
    _getPriceValue(data['compared_order_price'])
  ).toLocaleString('ru');
  const minPrice = data['min_price'].replace(' ', '\u00A0');

  return message.replace('_total_', minPrice).replace('_different_', addPrice);
}

function _getPriceValue(price) {
  return price ? price.match(/\d+(\.\d+)?/gi).join('') : 0;
}

export default getMinTotalMessage;
