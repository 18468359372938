import getOrderDiscount from './get_order_discount';
import formatPriceWithSpaces from './format_price_with_spaces';

const SELECTORS = {
  selectCheckbox: '.js-item-part-order-checkbox',
  ordersItem: '.js-orders-item',
};

function formatPrice(price, subject, withMeasureUnit) {
  const fixedPrice = Number(price).toFixed(2);
  const penny = String(fixedPrice).split('.');
  const resultPrice = penny && penny[1] > 0 ? fixedPrice : penny[0];
  if (!subject) {
    return resultPrice;
  }

  const measureUnitAddition =
    subject['measure_unit'] && withMeasureUnit
      ? '/' + subject['measure_unit'].name
      : '';
  return `${formatPriceWithSpaces(resultPrice)} ${
    subject.currency.title
  } ${measureUnitAddition}`;
}

function getItemSubjectForFormatting(items, $currentComponent) {
  const item = items.find(function (item) {
    return (
      isSelectedItemWithDiscount(item, $currentComponent) && !item['wholesale?']
    );
  });
  return (
    item && {
      measure_unit: item.subject['measure_unit'],
      currency: {...item['retail_currency']},
    }
  );
}

// Возвращает цену товара без скидки
function getOldItemFormattedPrice(product) {
  if (!isValidDiscount(product.subject)) {
    return;
  }

  return formatPrice(
    product['retail_price'],
    {
      measure_unit: product.subject['measure_unit'],
      currency: {...product['retail_currency']},
    },
    true
  );
}

// Возвращает сумму по товарной позиции без скидки
function getOldItemFormattedTotalPrice(ordersItemData) {
  if (
    !ordersItemData ||
    !isValidDiscount(ordersItemData.subject) ||
    ordersItemData['wholesale?']
  ) {
    return;
  }

  const totalPrice =
    Number(ordersItemData['retail_price']) * Number(ordersItemData.quantity);
  return formatPrice(totalPrice, {
    measure_unit: ordersItemData.subject['measure_unit'],
    currency: {...ordersItemData['retail_currency']},
  });
}

// Проверяет есть ли у товара скидка и действует ли она на данный момент времени
function isValidDiscount(subject) {
  if (!subject) {
    return;
  }

  const expires = Date.parse(String(subject['discount_expires_at']));
  const today = Date.now();
  return !!(subject && subject['is_discount'] && today - expires < 0);
}

// Проверяет товар на наличие скидки и отметку чекбоксом
function isSelectedItemWithDiscount(item, $currentComponent) {
  let selectedItem = item.selected || typeof item.selected === 'undefined';
  if ($currentComponent && !app.config.isMobile) {
    const $currentItem = $currentComponent.find(
      SELECTORS.ordersItem + '[data-id=' + item.id + ']'
    );
    selectedItem = $currentItem.find(SELECTORS.selectCheckbox).prop('checked');
  }
  return selectedItem && isValidDiscount(item.subject);
}

// Определяет наличие хотябы одного товара со скидкой среди выбранных товаров
function showDiscountBlock(items, $currentComponent) {
  return items.some(function (item) {
    return isSelectedItemWithDiscount(item, $currentComponent);
  });
}

function getFromOldTotal(itemsTotalPrice, addition) {
  const from = Number(itemsTotalPrice.from) + addition;
  return 'от ' + from.toFixed(2);
}

function getToOldTotal(itemsTotalPrice, addition) {
  const to = Number(itemsTotalPrice.to) + addition;
  return 'до ' + to.toFixed(2);
}

function getIntervalOldTotalSum(itemsTotalPrice, addition) {
  return (
    getFromOldTotal(itemsTotalPrice, addition) +
    ' ' +
    getToOldTotal(itemsTotalPrice, addition) +
    ' ' +
    itemsTotalPrice['currency_title']
  );
}

// Возвращает стоимость заказа без учета скидки
function getFormattedItemsTotalOld({
  items,
  itemsTotalPrice,
  delivery,
  $currentComponent,
}) {
  if (!showDiscountBlock(items, $currentComponent)) {
    return '';
  }

  const discount = getTotalItemsDiscount(items, $currentComponent);
  const deliveryPrice = delivery
    ? Number(delivery['formatted_price'].replace(/\D/g, ''))
    : 0;
  const addition = discount + deliveryPrice;

  if (itemsTotalPrice.type === 'exact') {
    const subject = getItemSubjectForFormatting(items, $currentComponent);
    return formatPrice(
      Number(itemsTotalPrice['exact_value']) + addition,
      subject
    );
  }
  if (itemsTotalPrice.type === 'interval') {
    return getIntervalOldTotalSum(itemsTotalPrice, addition);
  }
  if (itemsTotalPrice.type === 'from') {
    return (
      getFromOldTotal(itemsTotalPrice, addition) +
      ' ' +
      itemsTotalPrice['currency_title']
    );
  }
  if (itemsTotalPrice.type === 'to') {
    return (
      getToOldTotal(itemsTotalPrice, addition) +
      ' ' +
      itemsTotalPrice['currency_title']
    );
  }

  return '';
}

// Возвращает сумму скидки по всем товарам заказа
function getTotalItemsDiscount(items, $currentComponent) {
  return items.reduce(function (acc, item) {
    if (
      !isSelectedItemWithDiscount(item, $currentComponent) ||
      item['wholesale?']
    ) {
      return acc;
    }

    const discount = (
      item['retail_price'] - item['retail_discount_price']
    ).toFixed(2);
    return acc + discount * item.quantity;
  }, 0);
}

// Возвращает форматированную сумму скидки по всем товарам заказа с учетом скидки по промокоду
function getFormattedOrderDiscount(order, $currentComponent) {
  const discount = getTotalItemsDiscount(order.items, $currentComponent);
  return getOrderDiscount(order, discount);
}

export default {
  getOldItemFormattedPrice,
  getFormattedItemsTotalOld,
  getOldItemFormattedTotalPrice,
  getFormattedOrderDiscount,
  showDiscountBlock,
};
