import cartLoader from './cart-loader';
import cartItem from './orders-cart-item';
import {COMMON_SELECTORS} from '../constants/selectors';
import {
  ORDERS_CART_SCOPE_NAME,
  EMPTY_ORDERS_CART_SCOPE_NAME,
  ORDERS_SHOW_CART_SCOPE_NAME,
} from '../constants/common';
import store from '../store';
// Компонент корзины заказов

function getURLParameter(param) {
  const pageURL = window.location.search.substring(1),
    URLVariables = pageURL.split('&');
  let parameterName, i;

  for (i = 0; i < URLVariables.length; i++) {
    parameterName = URLVariables[i].split('=');
    if (parameterName[0] === param) {
      return decodeURIComponent(parameterName[1]);
    }
  }
}

const getItemsCount = () => {
  const orders = store.getOrders();
  return orders.reduce((result, order) => (result += order.items.length), 0);
};

function updateOrdersCounter() {
  const $counters = $(
    COMMON_SELECTORS.ordersCartCounter + ', ' + COMMON_SELECTORS.sideMenuCounter
  );
  if (!$counters.length) {
    return;
  }
  $counters.first().trigger('update:ordersCartCounter', [$counters]);
}

function renderBackLink(returnTo) {
  const linkTemplate = store.getOrdersCartTemplates().backLinkTemplate;
  if (!linkTemplate) {
    return;
  }
  $('.js-page-main-header:empty').append(linkTemplate({returnTo}));
}

function getOrdersCartView(isEmpty) {
  const config = store.getStoreDataByScopeName(ORDERS_CART_SCOPE_NAME);
  const template = store.getOrdersCartTemplates().customerCartTemplate;
  const returnTo =
    getURLParameter('return_to') || (config ? config.backURL : '');
  const showTip = store.getStoreDataByScopeName(ORDERS_SHOW_CART_SCOPE_NAME)
    ? store.getStoreDataByScopeName(ORDERS_SHOW_CART_SCOPE_NAME).showTip
    : '';
  !isEmpty && renderBackLink(returnTo);

  return template({
    configs: config,
    showTip,
    returnTo,
    isEmpty,
    itemsCount: getItemsCount(),
  });
}

function getOrdersCartEmptyView() {
  const template = store.getOrdersCartTemplates().emptyCartTemplate;
  const options = store.getStoreDataByScopeName(EMPTY_ORDERS_CART_SCOPE_NAME);
  return template(options);
}

function renderOrdersCart() {
  const container = $(COMMON_SELECTORS.cartContainer);
  container.html(getOrdersCartView());
  cartItem.renderCartItems();
  cartLoader.renderOrdersCartLoader();
  document.dispatchEvent(new CustomEvent('orders:renderComplete'));
}

function renderOrdersCartEmpty() {
  const container = $(COMMON_SELECTORS.cartContainer);
  container
    .html(getOrdersCartView(true))
    .find(COMMON_SELECTORS.ordersCartContentArea)
    .html(getOrdersCartEmptyView());
}

const renderCartAuthComponent = () => {
  const ordersCartAuthComponent =
    store.getStoreData().projectConfig.ordersCartAuthComponent;
  if (!ordersCartAuthComponent) {
    return;
  }
  ordersCartAuthComponent.render();
};

function onRenderOrdersCart(isEmpty) {
  if (isEmpty) {
    renderOrdersCartEmpty();
    return;
  }

  renderOrdersCart();
  renderCartAuthComponent();
}

export default {
  render: onRenderOrdersCart,
  updateOrdersCounter,
};
