export const getCommunicationType = (orderSettings) => {
  if (!(orderSettings && orderSettings.forms)) {
    return 'full';
  }
  if (
    orderSettings.forms['require_email'] &&
    !orderSettings.forms['require_phone']
  ) {
    return 'email';
  }
  if (
    orderSettings.forms['require_phone'] &&
    !orderSettings.forms['require_email']
  ) {
    return 'phone';
  }
  return 'multiple';
};
