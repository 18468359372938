var Handlebars = require("../../../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"cart-icon-tip js-cart-icon-tip\">\n  <a class=\"cit-link\" href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"cartUrl") : depth0), depth0))
    + "\">\n    "
    + alias1(__default(require("../../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"views_company_admin_toolbar_save_order",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":53}}}))
    + "\n  </a>\n  <div class=\"cit-close js-cart-icon-tip-close\"></div>\n</div>\n";
},"useData":true});