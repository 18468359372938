import toggleLoader from '../components/loader';
import {selectCartItemById} from '../utils/select-cart-item';
import api from '../constants/api';

function updateProductItem({itemId, data}) {
  const cartItem = selectCartItemById(data.item.cartItemId);
  toggleLoader(cartItem, true);

  return fetch(api.getProductItemURL(itemId), {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .finally(() => toggleLoader(cartItem, false));
}

export default updateProductItem;
