import {ORDERS_CART_ITEMS_PER_PAGE} from './constants/common';

export const INITIAL_STORE_STATE = {
  orders: [],
  flatCart: {},
  ordersItem: {},
  additionalInfo: {
    validationSchema: {
      phone: '',
      region: '',
      message: '',
      segmentation: '',
    },
  },
  loader: {
    limit: ORDERS_CART_ITEMS_PER_PAGE,
    offset: 0,
    orderItemsRemain: null,
    cartItemsRemain: null,
  },
  removedCartItems: 0,
};
