// Пробрасываем клики отложенным модулям после их загрузки.

app.modules.ordersListener = ((self = {}) => {
  function _handleOrdersClick(event) {
    const target = event.target.closest(
      '.js-get-order-form, .js-add-orders-item, .js-create-appeal-order'
    );
    if (app.modules.orderForm || !target) return;

    target.tagName !== 'A' && event.preventDefault();
    document.removeEventListener('click', _handleOrdersClick);

    apressImport(app.deferredPackageUrls.orders).then(() => {
      $(target).trigger('click', 'deferred');
    });
  }

  function _handleAddReviewClick(event) {
    const target = event.target.closest('.js-reviews-open-form');
    if (!target || app.modules.authComponent) {
      return;
    }

    event.preventDefault();
    document.removeEventListener('click', _handleAddReviewClick);

    apressImport(app.deferredPackageUrls.authComponent);
  }

  function _listener() {
    document.addEventListener('click', _handleOrdersClick);
    document.addEventListener('click', _handleAddReviewClick);
  }

  self.load = _listener;
  return self;
})(app.modules.ordersListener);
