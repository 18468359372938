// Попап предпросмотра корзины.
import cartPreview from '@apress/gems/cosmos_client/src/common/cart_preview/index';
import cartCounter from '@apress/gems/cosmos_client/src/common/orders_cart_counter';
import cartPreviewPopup from './templates/cart_preview_popup.handlebars';
import get from 'lodash.get';
import './cart_preview.scss';

export default app.modules.initCartPreview = ((self = {}) => {
  function _isCartPreviewLocked() {
    return (
      app.config.isCartPage ||
      app.config.isServicePage ||
      (!get(app, 'config.company.companySiteCartEnabled') &&
        /\/about/.test(window.location.pathname)) ||
      /(?:\/new|\/edit)/.test(window.location.pathname)
    );
  }

  function _closeAnotherPopups() {
    const ckToolbarSubmenuOpenElement = document.querySelector(
      '.js-dashboard-menu.active'
    );
    ckToolbarSubmenuOpenElement && ckToolbarSubmenuOpenElement.click();

    document.documentElement.click();
  }

  function _init() {
    cartCounter.init();
    if (_isCartPreviewLocked()) {
      return;
    }

    cartPreview.init(cartPreviewPopup, {beforeOpen: _closeAnotherPopups});
    // Убираем всплывающую подсказку на счетчике, если разрешен показ превью корзины
    const counter = document.querySelector('.orders-cart-counter-tooltip');
    counter && counter.classList.remove('orders-cart-counter-tooltip');
  }

  function _listener() {
    $doc.on('toolbar:load', _init);
  }

  function _onLoad() {
    const toolbar = document.querySelector('.js-main-toolbar');
    toolbar ? _init() : _listener();
  }

  self = {
    load: _onLoad,
    isCartPreviewLocked: _isCartPreviewLocked,
  };
  return self;
})(app.modules.initCartPreview);
