import get from 'lodash.get';
import isOnlinePaymentAvailable from './is-online-payment-available';

function isPaymentWithConfirmation(order) {
  if (!order || !order.company) {
    return;
  }
  return !!(
    get(
      order,
      'company.order_settings.payments.common.require_order_confirmation'
    ) && isOnlinePaymentAvailable(order)
  );
}

export default isPaymentWithConfirmation;
