import store from '../store';

function getMeasureUnitName(item) {
  if (!item) {
    return;
  }

  const typePrice = item['wholesale?']
    ? 'wholesale_measure_unit'
    : 'measure_unit_object';
  const measureUnitId = item[typePrice] && item[typePrice].id;
  const quantity = item['quantity'];

  const measureUnitItem = store
    .getMeasureUnits()
    .find((measureUnitItem) => measureUnitItem.id === measureUnitId);

  return (
    measureUnitItem &&
    app.modules.applicationUtils.pluralize(quantity, [
      measureUnitItem['name'],
      measureUnitItem['name_plural_1'],
      measureUnitItem['name_plural_2'],
      measureUnitItem['name_plural_2'],
    ])
  );
}

export default getMeasureUnitName;
