var Handlebars = require("../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "          <input\n            type=\"radio\"\n            name=\"choice\"\n            value=\""
    + alias2(alias1(depth0, depth0))
    + "\"\n            id=\""
    + alias2(alias1(depth0, depth0))
    + "\"\n            class=\"choose-online-payment-modal__input js-choose-online-payment-modal__input\"\n          />\n          <label\n            for=\""
    + alias2(alias1(depth0, depth0))
    + "\"\n            class=\"choose-online-payment-modal__label choose-online-payment-modal__label_"
    + alias2(alias1(depth0, depth0))
    + "\"\n          ></label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"choose-online-payment-modal js-choose-online-payment-modal\">\n  <div class=\"choose-online-payment-modal__inner\">\n    <button\n      type=\"button\"\n      class=\"choose-online-payment-modal__close js-choose-online-payment-modal__close\"\n      aria-label=\"Закрыть попап выбора способа оплаты\"\n    ></button>\n    <h2 class=\"choose-online-payment-modal__title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":8,"column":51},"end":{"line":8,"column":60}}}) : helper)))
    + "</h2>\n    <div class=\"choose-online-payment-modal__content\">\n      <p class=\"choose-online-payment-modal__subtitle\">Выберите удобный для вас сервис онлайн-оплаты заказа:</p>\n      <div class=\"choose-online-payment-modal__providers-wrap\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"providers") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":24,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <button\n      class=\"choose-online-payment-modal__pay-btn js-choose-online-payment-modal__pay-btn\"\n      type=\"button\"\n    >\n      Перейти к оплате\n    </button>\n  </div>\n</div>\n";
},"useData":true});