const moduleName = 'favoriteProducts';

vuexStore.hasModule(moduleName) ||
  vuexStore.registerModule(moduleName, {
    namespaced: true,
    state: {
      count: 0,
      products: [],
    },
    mutations: {
      setCount(state, value) {
        state.count = value;
      },
      setProducts(state, payload) {
        state.products = payload;
      },
      addProduct(state, payload) {
        state.products = [payload, ...state.products];
      },
      removeProduct(state, payload) {
        state.products = state.products.filter(
          (item) => item.id !== Number(payload)
        );
      },
    },
  });
