// Функция получения полей АК и контактного телефона если тип комуникации с компанией "full".

import {getFieldsForEmail} from '../helpers/get-fields-for-email';

/**
 * @see docs/frontend/common/communication.md
 */
export const getFieldsForFull = (order) => {
  const contactField = {
    contacts: true,
    contactsRequired: true,
  };

  return order.user.email
    ? contactField
    : Object.assign(getFieldsForEmail(order), contactField);
};
