// Всплывающая подсказка для пользователей без почты

import tipTemplate from './templates/tip.handlebars';
import cartPreview from '../../customer-cart/cart_preview';

app.modules.cartIconTip = ((self = {}) => {
  const blockingCookieName = 'hideCartIconTip';
  const classes = {
    close: 'js-cart-icon-tip-close',
  };
  const selectors = {
    container: '.js-orders-cart-counter-container',
    tip: '.js-cart-icon-tip',
    close: `.${classes.close}`,
  };

  function _preventTipShowing() {
    $.cookie(blockingCookieName, 'true', {
      expires: 1,
      path: '/',
      domain: '.' + app.config.shortHost,
    });
  }

  function _isTipShowBlocked() {
    if ($.cookie(blockingCookieName)) {
      return true;
    }
  }

  function _showTip() {
    const container = document.querySelector(selectors.container);
    if (
      _isTipShowBlocked() ||
      !container ||
      !cartPreview.isCartPreviewLocked()
    ) {
      return;
    }
    container.innerHTML += tipTemplate({cartUrl: app.config.pageUrls.cart});
  }

  function _toggleTip() {
    const toggle = (orders = []) => {
      const email = orders[0] && orders[0].user.email;
      const tip = document.querySelector(selectors.tip);

      if (orders.length && !email) {
        !tip && _showTip();
      } else {
        tip && tip.remove();
      }
    };

    fetch(app.config.api.customerCartOrdersURL)
      .then((res) => res.json())
      .then((data) => toggle(data.orders));
  }

  function _closeTip(event) {
    const target = event.target;
    if (!target.classList.contains(classes.close)) {
      return;
    }

    document.querySelector(selectors.tip).remove();
    _preventTipShowing();
  }

  function _listener() {
    document.addEventListener('showTip:cartIconTip', _toggleTip);
    document.addEventListener('click', _closeTip);
  }

  function _init() {
    if (app.config.isCartPage || !cartPreview.isCartPreviewLocked()) {
      return;
    }

    app.config.isUserSigned && _toggleTip();
    _listener();
  }

  self.load = _init;
  return self;
})(app.modules.cartIconTip);
