import {getFieldsForEmail} from '../helpers/get-fields-for-email';
import {getFieldsForPhone} from '../helpers/get-fields-for-phone';
import {getFieldsForFull} from '../helpers/get-fields-for-full';
import {getFieldsForMultiple} from '../helpers/get-fields-for-multiple';

/**
 * @see docs/frontend/common/communication.md
 */
export const COMMUNICATION_TYPE_TO_HELPER = {
  email: getFieldsForEmail,
  phone: getFieldsForPhone,
  full: getFieldsForFull,
  multiple: getFieldsForMultiple,
};
