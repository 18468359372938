<template>
  <a :class="`favorite-products-counter ${additionalClass}`" :href="url">
    <div class="favorite-products-counter__content">{{ count }}</div>
  </a>
</template>

<script>
export default {
  name: 'favorite-products-counter',
  computed: Vuex.mapState({
    count(state) {
      return state.favoriteProducts.count || this.defaultEmptyCount;
    },
  }),
  props: {
    url: String,
    additionalClass: String,
    defaultEmptyCount: String,
  },
};
</script>
