import {LOCALES} from '../constants/common';

const pluralizeProducts = (count) =>
  app.modules.applicationUtils.pluralize(count, [
    LOCALES.remainingCount.products.one,
    LOCALES.remainingCount.products.few,
    LOCALES.remainingCount.products.many,
  ]);

export default pluralizeProducts;
