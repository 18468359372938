// Инициализация yandex map Api

app.modules.loadYandexMapApi = (function (self) {
  let _scriptLoad;
  let _map;

  function _getScript(event) {
    _map = event.detail;
    if (_scriptLoad === true) {
      _callback();
      return;
    }
    const script = document.createElement('script');
    script.src = app.config.yandexMapApiUrl;
    script.onload = function () {
      _scriptLoad = true;
      _callback();
    };
    document.body.appendChild(script);
  }

  function _callback() {
    const eventName =
      _map === 'contacts'
        ? 'loadYandexMapApi:contacts'
        : 'loadYandexMapApi:popup';
    document.dispatchEvent(new Event(eventName));
  }

  function _listeners() {
    document.addEventListener('getYandexMapApi', _getScript);
  }

  self.load = _listeners;

  return self;
})(app.modules.loadYandexMapApi || {});
