import {cancelDelivery} from './delivery/delivery-info-manager';
import {
  SELECTRIC_OPTIONS_SCOPE_NAME,
  SPINNER_OPTIONS_SCOPE_NAME,
  CONTRACT_CURRENCY_ID,
} from '../constants/common';
import {LOCALES} from '../constants/common';
import updateProductItemRequest from '../common/update-product-item';
import deleteProductItem from '../common/delete-product-item';
import getCartItemsData from '../common/get-cart-items-data';
import cartItem from './orders-cart-item';
import {
  selectCartItemById,
  selectCartItemByTarget,
} from '../utils/select-cart-item';
import {
  selectProductItemById,
  selectProductItemByTarget,
} from '../utils/select-product-item';
import partOrder from './part-order';
import discount from '../utils/order-items-discount';
import {projectModules} from '../init-cart';
import store from '../store';
import {
  COMMON_SELECTORS,
  QUANTITY_SELECTORS,
  PRODUCT_SELECTORS,
} from '../constants/selectors';
import _get from 'lodash.get';
import getMeasureUnitName from '../utils/get-measure-unit-name';
// Компонент единицы заказа

let delayedCallback = function () {};

function getOrdersToUpdateStore(newOrderData, orderId) {
  const orders = $.extend([], store.getOrders()),
    orderIndex = orders.findIndex(function (order) {
      return order.id === orderId;
    });

  if (orderIndex < 0) {
    return;
  }

  newOrderData
    ? (orders[orderIndex] = newOrderData)
    : orders.splice(orderIndex, 1);

  return orders;
}

function filterFlatCartItems(orderId, id) {
  const flatCart = store.getFlatCart();

  flatCart[orderId].items = flatCart[orderId].items.filter(function (item) {
    return item.id !== id;
  });
  return flatCart;
}

function updateOrdersInStoreData({order, item, orderId, itemId}) {
  const updatedOrder = order || (item && item.order),
    oldOrder = store.getOrderById(orderId),
    orders = getOrdersToUpdateStore(updatedOrder, orderId),
    flatCart = filterFlatCartItems(orderId, itemId);

  if (!orders) {
    return;
  }

  store.updateStore({orders, flatCart}, 'updateCounter');
  oldOrder.delivery && cancelDelivery(orderId);
}

function renderOrdersItems($target, cartItemID) {
  commonListener();
  initPlugins($target.html(getOrdersItemsView(cartItemID)));
  partOrder.init();
}

function getPluralizedMeasureUnitName(measureUnitID, count) {
  const measureUnitItem = store
    .getMeasureUnits()
    .filter(function (measureUnitItem) {
      return measureUnitItem.id === measureUnitID;
    })[0];

  return (
    measureUnitItem &&
    app.modules.applicationUtils.pluralize(count, [
      measureUnitItem['name'],
      measureUnitItem['name_plural_1'],
      measureUnitItem['name_plural_2'],
      measureUnitItem['name_plural_2'],
    ])
  );
}

function getOrdersItemsToRender(cartItemID) {
  return cartItemID
    ? store.getFlatCart()[cartItemID].items
    : [store.getStoreDataByScopeName('item')];
}

const getHintInfo = (item) => {
  if (!item) {
    return;
  }

  const subject = item.subject;
  const state = subject && subject['public_state'];

  if (!subject || state === 'deleted') {
    return LOCALES.ordersItem.messageDeleted;
  }
  if (subject.exists === 'not_available') {
    return LOCALES.ordersItem.messageNotAvailable;
  }
};

const getToggleMinQty = (item, wholesalePluralizedMeasureUnitName) => {
  const minQty = LOCALES.paymentType.minQty;
  if (!minQty) {
    return;
  }

  return minQty
    .replace('%{count}', item['wholesale_min_qty'])
    .replace('%{measure}', wholesalePluralizedMeasureUnitName);
};

function prepareSpinnerOptions(item) {
  const options = Object.assign(
    {},
    _get(app, 'config.ordersCart.spinnerOptions', {}),
    {id: item.id}
  );
  return JSON.stringify(options);
}

function prepareConversionsItemData(item) {
  if (!item) return;

  return JSON.stringify({
    company_id: item.company_id,
    price: item.price,
    rate: item.rate,
  });
}

function prepareDataToRender(ordersItemData, cartItemID) {
  const wholesalePluralizedMeasureUnitName =
    ordersItemData['wholesale_min_qty'] &&
    getPluralizedMeasureUnitName(
      ordersItemData['wholesale_measure_unit'].id,
      ordersItemData['wholesale_min_qty']
    );

  return $.extend(ordersItemData, {
    hasRetailPrice:
      ordersItemData['formatted_retail_price'] !==
      app.config.cartItemPriceAgreed,
    company: cartItemID && store.getFlatCart()[cartItemID].company,
    countMoreOneItemsInCart:
      cartItemID && store.getFlatCart()[cartItemID].items.length > 1,
    cartItemID: cartItemID,
    wholesalePluralizedMeasureUnitName,
    measureUnitName: getMeasureUnitName(ordersItemData),
    checkboxVisible: selectCartItemById(cartItemID).data('checkboxVisible'),
    formattedOldPrice: discount.getOldItemFormattedPrice(ordersItemData),
    formattedOldTotalPrice:
      discount.getOldItemFormattedTotalPrice(ordersItemData),
    hintInfo: getHintInfo(ordersItemData),
    toggleMinQty: getToggleMinQty(
      ordersItemData,
      wholesalePluralizedMeasureUnitName
    ),
    spinnerOptions: prepareSpinnerOptions(ordersItemData),
    conversionsItemData: prepareConversionsItemData(ordersItemData.subject),
  });
}

function getOrdersItemsView(cartItemID) {
  return getOrdersItemsToRender(cartItemID).reduce(function (
    $result,
    ordersItemData
  ) {
    return $result.append(
      componentListener(getOrdersItemView(ordersItemData, cartItemID))
    );
  },
  $('<div>'));
}

function getOrdersItemView(ordersItemData, cartItemID) {
  const storeData = store.getStoreData();
  const template = store.getOrderItemTemplates().ordersItemTemplate;
  const spinner = store.getOrderItemTemplates().spinnerItemTemplate;
  const measureUnits = storeData.measureUnits;

  const configSpinner = {
    value: ordersItemData.quantity,
    className: 'js-orders-item__count js-price-type-toggle__quantity-field',
    validationMessage: LOCALES.paymentType.spinnerValidationError,
    itemID: ordersItemData.id,
    itemName: 'ordersQuantity',
    min: getMinValueQuantity(ordersItemData),
    max: 99999,
  };

  const applicationSpinner = spinner
    ? spinner(configSpinner)
    : Handlebars.partials['application/_spinner'](configSpinner);
  const applicationSelectric = Handlebars.partials['application/_selectric']({
    options: measureUnits,
    className:
      'js-orders-item-measure-units js-price-type-toggle__measure-units',
    validationMessage: LOCALES.paymentType.measureUnitsValidation,
    itemID: ordersItemData.id,
    itemName: 'ordersMeasureUnits',
    maxHeight: 120,
  });

  return $(
    template({
      item: prepareDataToRender(ordersItemData, cartItemID),
      measureUnits,
      config: storeData.ordersItem,
      applicationSpinner,
      applicationSelectric,
    })
  );
}

function deleteOrdersItemView($component) {
  const currentCartItem = selectCartItemByTarget($component);
  $component.remove();
  partOrder.setCompanyCheckboxStatus(currentCartItem);
}

function updateTotal(item) {
  const oldPrice = discount.getOldItemFormattedTotalPrice(item);
  const $component = selectProductItemById(item.id);

  $component
    .find(PRODUCT_SELECTORS.total)
    .html(item['formatted_total'])
    .end()
    .find(PRODUCT_SELECTORS.totalOld)
    .toggleClass('dn', !oldPrice)
    .html(oldPrice);
}

function isRetailOrContractCurrency(ordersItemData) {
  return (
    ordersItemData['retail_price'] ||
    (!ordersItemData['retail_price'] &&
      ordersItemData['subject'] &&
      ordersItemData['subject']['currency']['id'] == CONTRACT_CURRENCY_ID) ||
    !ordersItemData['wholesale_min_qty']
  );
}

function getMinValueQuantity(ordersItemData) {
  return isRetailOrContractCurrency(ordersItemData)
    ? 1
    : ordersItemData['wholesale_min_qty'];
}

function initPlugins($ordersItems) {
  const $quantityField = $ordersItems.find(QUANTITY_SELECTORS.fields.quantity),
    $measureUnitsField = $ordersItems.find(
      QUANTITY_SELECTORS.fields.measureUnits
    );

  if (!$quantityField.data('customSpinner')) {
    $quantityField
      .extendedSpinner(
        $.extend($quantityField.data(SPINNER_OPTIONS_SCOPE_NAME), {
          spin: onUpdateQuantity,
        })
      )
      .numeric({decimalPlaces: 2, negative: false});
  }

  $measureUnitsField.selectric(
    $.extend($measureUnitsField.data(SELECTRIC_OPTIONS_SCOPE_NAME))
  );
}

function onMouseout() {
  delayedCallback();
  delayedCallback = function () {};
}

function componentListener($component) {
  return $component
    .on('click', PRODUCT_SELECTORS.delete, onDeleteProductItem)
    .on(
      'mouseout',
      [
        QUANTITY_SELECTORS.container,
        QUANTITY_SELECTORS.measureUnitsContainer,
      ].join(','),
      onMouseout
    );
}

function onUpdateProductItem(evt, productItemData) {
  const itemId = productItemData.id;
  const orderId = productItemData.cartItemId;
  const customerRegion = store.getOrderById(orderId)['customer_region_id'];
  const orderData = {customer_region_id: customerRegion};
  const data = {item: productItemData, order: orderData};

  const request = function () {
    updateProductItemRequest({itemId, data}).then(({item}) => {
      updateTotal(item);
      getCartItemsData({orderId});
      updateOrdersInStoreData({item, orderId});
      projectModules.toggleQuantity(
        Object.assign(item, {measureUnitName: getMeasureUnitName(item)})
      );
    });
  };

  delayedCallback = app.modules.applicationUtils.delayCall(request);
}

export function onUpdateQuantity(event, ui) {
  const $target = $(event.target),
    quantity = (ui && (ui.value || ui.quantity)) || Number($target.val()) || 1,
    productItemData = selectProductItemByTarget($target).data(),
    data = $.extend({}, productItemData, {quantity});

  onUpdateProductItem(event, data);
}

function setValueQuantityAndUpdate(event, ui) {
  const $target = $(event.target);
  const minQuantity = Number(
    JSON.parse($target.attr('data-spinner-options')).min
  );
  const targetValueQuantity = Number($target.val());

  $target.val(
    minQuantity < targetValueQuantity ? targetValueQuantity : minQuantity
  );
  onUpdateQuantity(event, ui);
}

function commonListener() {
  $doc.on('input', QUANTITY_SELECTORS.fields.quantity, onUpdateQuantity);
  $doc.on(
    'blur',
    QUANTITY_SELECTORS.fields.quantity,
    setValueQuantityAndUpdate
  );
  document.addEventListener('changeInputQuantity', setValueQuantityAndUpdate);
}

export function onSuccessProductDelete(order, itemId, cartItemId) {
  const $currentComponent = selectProductItemById(itemId),
    hasAnyOrderItem = $currentComponent.siblings(
      COMMON_SELECTORS.orderItem
    ).length;

  updateOrdersInStoreData({order, orderId: cartItemId, itemId});

  if (hasAnyOrderItem) {
    deleteOrdersItemView($currentComponent);
    cartItem.updateTotal(order);
  } else {
    cartItem.remove(cartItemId);
  }
}

export function onDeleteProductItem(evt) {
  const productItemData = selectProductItemByTarget($(evt.target)).data(),
    itemId = productItemData.id,
    cartItemId = productItemData.cartItemId;

  deleteProductItem({itemId, cartItemId});
}

export function onFailProductDelete() {
  alert('Не удалось удалить товар из корзины');
}

export default renderOrdersItems;
