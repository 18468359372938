import ordersCart from './components/orders-cart';
import {
  ADDITIONAL_INFO_DATA_SCOPE_NAME,
  CART_DATA_SCOPE_NAME,
} from './constants/common';
import {COMMON_SELECTORS} from './constants/selectors';
import {INITIAL_STORE_STATE} from './initial-store';
import _get from 'lodash.get';

const $store = $('<store>');

function initStore(projectConfig) {
  const containerData = $(COMMON_SELECTORS.cartContainer).data(
    CART_DATA_SCOPE_NAME
  );
  if (app.config.ordersCart.companyId) {
    INITIAL_STORE_STATE.loader['company_ids'] = app.config.ordersCart.companyId;
  }

  const storeData = $.extend(true, {}, INITIAL_STORE_STATE, containerData, {
    projectConfig,
  });

  $store.data(storeData);
}

const getStoreData = () => $store.data();

const getOrders = () => $store.data('orders') || [];

const getOrderById = (id) => getOrders().find((item) => item.id === +id);

function getOrderByElement($element) {
  if (!$element) {
    return;
  }

  const orderId = $element.closest(COMMON_SELECTORS.ordersCartItem).data('id');
  return getOrderById(orderId);
}

const getOrderProductById = (orderId, itemId) => {
  const order = getOrders().find((item) => item.id === +orderId);
  return order.items.find(({id}) => id === itemId);
};

const updateStore = (newStore, updateCounter) => {
  $store.data(Object.assign({}, $store.data(), newStore));
  updateCounter && ordersCart.updateOrdersCounter();
};

function updateOrderInStore(order) {
  var orders = getOrders().filter(function (item) {
    return item.id !== order.id;
  });
  orders.push(order);
  updateStore({orders});
}

const updateAdditionalInfo = (info) => {
  updateStore({
    additionalInfo: Object.assign(
      {},
      getStoreDataByScopeName(ADDITIONAL_INFO_DATA_SCOPE_NAME),
      info
    ),
  });
};

const updateValidationSchema = (schema) => {
  const validSchema = schema && Object.keys(schema).length;
  const additionalInfo = getStoreDataByScopeName(
    ADDITIONAL_INFO_DATA_SCOPE_NAME
  );
  const newSchema = Object.assign(
    additionalInfo.validationSchema,
    validSchema ? schema : INITIAL_STORE_STATE.additionalInfo.validationSchema
  );
  updateAdditionalInfo({validationSchema: newSchema});
};

const getFlatCart = () => $store.data('flatCart');

function setAdditionalMessage(message, orderId) {
  getFlatCart()[orderId].additionalMessage = message;
}

const getDeliveryTemplates = () => {
  return _get(getStoreData(), 'projectConfig.delivery.templates');
};

const getStore = () => $store;

const getStoreDataByScopeName = (scopeName) => $store.data(scopeName);

const getMeasureUnits = () => $store.data('measureUnits');

const getOrdersCartTemplates = () =>
  _get(getStoreData(), 'projectConfig.ordersCart.templates');

const getCartItemTemplates = () =>
  _get(getStoreData(), 'projectConfig.cartItem.templates');

const getAdditionalInfoTemplates = () =>
  _get(getStoreData(), 'projectConfig.additionalInfo.templates');

const getPayKeeperTemplates = () =>
  _get(getStoreData(), 'projectConfig.payKeeper.templates');

const getOrderItemTemplates = () =>
  _get(getStoreData(), 'projectConfig.ordersItem.templates');

const resetStoreDataByType = (type) =>
  $store.data(type, INITIAL_STORE_STATE[type]);

const manageCommunicationType = (type) => {
  return type
    ? $store.data('communicationType', type)
    : $store.data('communicationType');
};

const getFieldsValidationSchema = () =>
  getStoreDataByScopeName(ADDITIONAL_INFO_DATA_SCOPE_NAME).validationSchema;

const setDelivery = ({delivery, orderId}) =>
  (getFlatCart()[orderId].delivery = delivery);

export default {
  initStore,
  getOrderById,
  getOrderByElement,
  updateOrderInStore,
  getFlatCart,
  setAdditionalMessage,
  getDeliveryTemplates,
  getOrders,
  updateStore,
  getStore,
  getStoreDataByScopeName,
  getOrdersCartTemplates,
  getStoreData,
  getMeasureUnits,
  resetStoreDataByType,
  getOrderProductById,
  getCartItemTemplates,
  getAdditionalInfoTemplates,
  getPayKeeperTemplates,
  getOrderItemTemplates,
  manageCommunicationType,
  updateValidationSchema,
  getFieldsValidationSchema,
  updateAdditionalInfo,
  setDelivery,
};
