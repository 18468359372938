import getOrderInfo from '../../common/get-order-info';
import {setDeliveryTemplate} from '../../components/delivery/delivery-initializer';
import {toggleContactPhone} from '../../../common/communication/helpers/toggle-contact-phone';
import {projectModules} from '../../init-cart';
import getOrderDeliveries from '../../utils/get-order-deliveries';
import {selectCartItemById} from '../../utils/select-cart-item';
import cartItem from '../../components/orders-cart-item';
import store from '../../store';
import {
  COMMON_SELECTORS,
  PROMO_CODE_SELECTORS,
} from '../../constants/selectors';
import {HANDLERS, DELIVERY_SELECTORS, DELIVERIES} from './constants';
import {
  highlightButton,
  setAdditionalMessage,
  setDefaultAdditionalMessage,
} from './orders-cart-delivery-common';
import _get from 'lodash.get';

// перед изменением/удалением учитывать документацию!
// @see docs/frontend/delivery/README.md
function removeOldDeliveryInfo($prevContainer, typeSlug, order) {
  getOrderDeliveries(order)
    .filter((slug) => slug !== typeSlug)
    .forEach((slug) => {
      if (!HANDLERS[slug]) {
        return;
      }
      const deliveryContainerContent =
        HANDLERS[slug].getButtonTemplate(order) || '';
      $prevContainer
        .find(DELIVERY_SELECTORS[slug].container)
        .html(deliveryContainerContent);
    });
}

function setDeliveryInfo($container, typeSlug, order) {
  const $deliveryContainer = $container.find(
    DELIVERY_SELECTORS[typeSlug].container
  );
  $deliveryContainer.html(HANDLERS[typeSlug].getDeliveryInfoTemplate(order));
  toggleContactPhone({order});
}

function manageOldDeliveryInfo($container, typeSlug, order) {
  const oldDeliveryInfo = $container.find(DELIVERY_SELECTORS.deliveryInfo);
  const $prevContainer = oldDeliveryInfo.closest(
    COMMON_SELECTORS.ordersCartItem
  );

  oldDeliveryInfo && removeOldDeliveryInfo($prevContainer, typeSlug, order);
}

export const cancelDelivery = (orderId) => {
  getOrderInfo({
    orderId,
    onSuccess: function (response) {
      const {order} = response || {};
      const $container = selectCartItemById(order.id);
      $container
        .find(DELIVERY_SELECTORS.deliveriesItems)
        .removeClass('orders-cart-delivery__items_opened');

      DELIVERIES.forEach(function (slug) {
        setDeliveryTemplate(slug, order);
      });
      setDefaultAdditionalMessage(order);
      highlightButton($container);
      cartItem.updateTotal(order);
      store.updateOrderInStore(order);
      store.setDelivery({delivery: null, orderId});
      toggleContactPhone({order});

      if (
        !projectModules.promoCode &&
        $container.find(PROMO_CODE_SELECTORS.block).length
      ) {
        return;
      }
      projectModules.promoCode.handlePromoCode($container, response);
    },
  });
};

export const updateDelivery = ({
  deliveryData,
  orderId,
  onAfterSuccess,
  onAfterFail,
} = {}) => {
  const getParamsStr = deliveryData
    ? Object.keys(deliveryData).reduce((acc, key, idx, arr) => {
        return (
          acc +
          `delivery[${key}]=${deliveryData[key]}` +
          (idx < arr.length ? '&' : '')
        );
      }, '?')
    : '';

  getOrderInfo({
    orderId,
    getParamsStr,
    onSuccess: function (response) {
      const {order} = response || {};
      if (!order || !order.delivery) {
        onAfterFail && onAfterFail();
        return;
      }
      const typeSlug = _get(order, 'delivery.type_slug');

      const $container = selectCartItemById(order.id);
      $container
        .find(DELIVERY_SELECTORS.deliveriesItems)
        .addClass('orders-cart-delivery__items_opened', !!typeSlug);

      manageOldDeliveryInfo($container, typeSlug, order);
      setDeliveryInfo($container, typeSlug, order);
      setAdditionalMessage(typeSlug, order);
      highlightButton($container, DELIVERY_SELECTORS[typeSlug].selectButton);

      cartItem.updateTotal(order);
      store.updateOrderInStore(order);
      store.setDelivery({delivery: deliveryData, orderId});

      if (
        projectModules.promoCode &&
        $container.find(PROMO_CODE_SELECTORS.block).length
      ) {
        projectModules.promoCode.handlePromoCode($container, response);
      }
      onAfterSuccess && onAfterSuccess(order);
    },
  });
};
