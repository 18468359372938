var Handlebars = require("../../../../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"cart-preview-popup__item\">\n          <div class=\"cart-preview-popup__item-image-wrap "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"imageSrc") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":58},"end":{"line":6,"column":128}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imageSrc") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n          <div>\n            <h3 class=\"cart-preview-popup__item-name js-cart-preview-popup__item-name\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h3>\n            <span class=\"cart-preview-popup__item-price\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"price") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"beforeDiscountPrice") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":14},"end":{"line":21,"column":21}}})) != null ? stack1 : "")
    + "            </span>\n          </div>\n        </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "cart-preview-popup__item-image-cap";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <img\n                class=\"cart-preview-popup__item-image\"\n                src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"imageSrc") : depth0), depth0))
    + "\"\n                alt=\"\"\n              >\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"cart-preview-popup__item-price_discount\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"beforeDiscountPrice") : depth0), depth0))
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"cart-preview-popup__price-block\">\n          <span class=\"cart-preview-popup__total-label\">"
    + alias1(__default(require("../../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"cart_preview.total_label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":56},"end":{"line":30,"column":91}}}))
    + "</span>\n          <span class=\"cart-preview-popup__total\">"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"formattedTotal") : depth0), depth0))
    + "</span>\n        </p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"cart-preview-popup__overlay\">\n  <div class=\"cart-preview-popup\">\n    <ul class=\"cart-preview-popup__list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n    <div class=\"cart-preview-popup__total-block\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"formattedTotal") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":6},"end":{"line":33,"column":13}}})) != null ? stack1 : "")
    + "      <a\n        class=\"cart-preview-popup__to-cart-link aui-button tmpl-company-button order-button\"\n        href=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"cartUrl") : depth0), depth0))
    + "\"\n      >\n        "
    + alias2(__default(require("../../../../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"cart_preview.to_cart",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":38,"column":39}}}))
    + "\n      </a>\n    </div>\n  </div>\n</div>\n";
},"useData":true});