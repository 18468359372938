/**
 * Модуль отвечает за формирование свойства 'items_attributes' объекта place при создании заказа.
 * @param {Array} items Товары/услуги заказа.
 * @return {Object} { itemId: { 'measure_unit', selected } }
 */
const getItemsAttributes = (items) => {
  const itemsAttributes = {};

  items.forEach(function (item) {
    let measureUnitId;

    if (item['wholesale?']) {
      measureUnitId = item['wholesale_measure_unit'].id;
    } else {
      measureUnitId =
        item['measure_unit'] ||
        $('.js-orders-item' + '[data-id=' + item.id + ']')
          .find('.js-orders-item-measure-units')
          .val();
    }
    itemsAttributes[item.id] = {
      measure_unit: measureUnitId,
      selected: item.selected || typeof item.selected === 'undefined', // выбран ли товар при частичном заказе.
    };
  });

  return itemsAttributes;
};

export default getItemsAttributes;
