import discount from '../../cart/utils/order-items-discount';
import correctPopupPosition from '../../cart/utils/correct_popup_position';
import _get from 'lodash.get';
import {SELECTORS} from './constants';

/**
 * Модуль превью корзины.
 * @see docs/frontend/cart_preview.md
 */
export default app.modules.cartPreview = ((self = {}) => {
  let _template, cart, _beforeOpen;
  const CLASSES = {
    nameOverflowed: 'cart-preview-popup__item-name_overflowed',
  };

  const _getPopupData = (
    {items, formatted_amount: totalSum, orders_count: ordersCount},
    cartUrl
  ) => ({
    items: items.map((item) => ({
      imageSrc:
        item.image && item.image.styles.filter(({thumb}) => thumb)[0].thumb.url,
      name: item.name,
      price: item['formatted_total'],
      beforeDiscountPrice: discount.getOldItemFormattedTotalPrice(item),
    })),
    formattedTotal: ordersCount === 1 && totalSum,
    cartUrl,
  });

  /**
   * Получает все элементы, содержащие имена товаров и если имя занимает более 2-х строк, добавляет класс переполнения.
   */
  function _handleNameOverflow(popup) {
    const names = popup.querySelectorAll(SELECTORS.name);
    names.forEach((name) => {
      const lineHeight = window
        .getComputedStyle(name)
        .lineHeight.replace(/\D/g, '');
      if (name.clientHeight / lineHeight <= 2) {
        return;
      }
      name.style = `height: ${lineHeight * 2}px;`;
      name.classList.add(CLASSES.nameOverflowed);
    });
  }

  function _renderPopup(target, previewContainer, cart) {
    const cartLink = target.querySelector(SELECTORS.link),
      cartUrl = (cartLink && cartLink.getAttribute('href')) || '';

    previewContainer.innerHTML = _template(_getPopupData(cart, cartUrl));
    const popup = previewContainer.children[0];
    _handleNameOverflow(popup);
    correctPopupPosition({target, popup});
  }

  function _showCartPreview(event) {
    if (!_template || !_get(cart, 'items_count')) {
      return;
    }
    _beforeOpen && _beforeOpen();

    const target = event.target;
    const previewContainer = target.querySelector(SELECTORS.popupContainer);
    _renderPopup(target, previewContainer, cart);
    target.addEventListener('mouseleave', function clearPopup() {
      previewContainer.innerHTML = '';
      target.removeEventListener('mouseleave', clearPopup);
    });
  }

  function _updateCartData(cartData) {
    cart = cartData;
  }

  function _listener() {
    const counterContainer = document.querySelector(
      SELECTORS.ordersCartCounter
    );
    counterContainer &&
      counterContainer.addEventListener('mouseenter', _showCartPreview);
  }

  function init(cartPreviewPopup, options) {
    if (!cartPreviewPopup) {
      return;
    }
    const {beforeOpen} = options || {};
    _template = cartPreviewPopup;
    _beforeOpen = beforeOpen;
    _listener();
  }

  self = {init, updateCartData: _updateCartData};
  return self;
})(app.modules.cartPreview);
