// Функция получения полей АК и контактного телефона если тип комуникации с компанией "phone".

import {isUserSid} from '../../../cart/utils/user-authorization-level';

/**
 * @see docs/frontend/common/communication.md
 */
export const getFieldsForPhone = ({user: {phone}}) =>
  isUserSid()
    ? {
        auth: {
          set: ['phone', 'name'],
          required: ['phone', 'name'],
        },
      }
    : {
        contacts: true,
        contactsRequired: !phone,
      };
