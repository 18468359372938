// Компонент дополнительной информации для отправки заказа
import {toggleContactPhone} from '../../common/communication/helpers/toggle-contact-phone';
import authComponent from './auth-component/auth-component';
import {getOrderSettings} from '../utils/get-order-settings';
import {COMMUNICATION_TYPE_TO_HELPER} from '../../common/communication/constants/communication-type-to-helper';
import {getCommunicationType} from '../../common/communication/helpers/get-communication-type';
import {
  SELECTRIC_OPTIONS_SCOPE_NAME,
  ERRORS_MESSAGES_SCOPE_NAME,
  LOCALES,
} from '../constants/common';
import {
  COMMON_SELECTORS,
  ADDITIONAL_INFO_SELECTORS,
} from '../constants/selectors';
import {selectCartItemByTarget} from '../utils/select-cart-item';
import isPaymentWithConfirmation from '../utils/is-payment-with-confirmation';
import isOnlinePaymentAvailable from '../utils/is-online-payment-available';
import {ADDITIONAL_INFO_DATA_SCOPE_NAME} from '../constants/common';
import {initializeDeliveries} from '../components/delivery/delivery-initializer';
import store from '../store';

const CLASS_NAMES = {
  error:
    (app.config.ordersCart &&
      app.config.ordersCart.classNames.formGroupWithError) ||
    'with-error',
};

function initPlugins($ordersCartAdditionalInfo) {
  const $component = $ordersCartAdditionalInfo.children();
  const $regionsField = $component.find(
    ADDITIONAL_INFO_SELECTORS.fields.region
  );
  const $toggler = $('.js-toggle');

  $regionsField.selectric($regionsField.data(SELECTRIC_OPTIONS_SCOPE_NAME));
  $toggler.toggler && $toggler.toggler();
}

function getOrdersCartAdditionalInfoView() {
  const storeData = store.getStoreData(),
    regionsList = storeData.regions,
    regionId = storeData['customer_region_id'],
    region = regionsList.filter(function (item) {
      return item.id === regionId;
    }),
    regionName = region.length ? region[0].name : '✚',
    additionalInfoData = storeData[ADDITIONAL_INFO_DATA_SCOPE_NAME],
    order = additionalInfoData.order,
    template = store.getAdditionalInfoTemplates().additionalInfoTemplate,
    selectric = window.Handlebars.partials['application/_selectric']({
      value: regionId,
      options: regionsList,
      className: 'js-additional-info__regions',
      validationMessage: LOCALES.additionalInfo.selectricValidationError,
      itemID: additionalInfoData.orderId,
      itemName: 'ordersRegions',
      maxHeight: 240,
    });

  return $(
    template({
      data: additionalInfoData,
      customerRegionId: regionId,
      regions: regionsList,
      regionName: regionName,
      configs: app.config.ordersCart,
      paymentWithConfirmation: isPaymentWithConfirmation(order),
      onlinePayment: isOnlinePaymentAvailable(order),
      selectric,
    })
  );
}

function getComponentFieldsSelector() {
  return Object.keys(ADDITIONAL_INFO_SELECTORS.fields)
    .map(function (field) {
      return ADDITIONAL_INFO_SELECTORS.fields[field];
    })
    .join(',');
}

function _getComponentData() {
  return Object.keys(ADDITIONAL_INFO_SELECTORS.fields).reduce(function (
    result,
    fieldName
  ) {
    let fieldValue;

    if (fieldName === 'segmentation') {
      fieldValue = getSegmentationValue();
    } else {
      fieldValue = $.trim(
        $(ADDITIONAL_INFO_SELECTORS.fields[fieldName])[
          fieldName === 'phone' ? 'cleanVal' : 'val'
        ]()
      );
    }

    if (fieldValue) {
      result[fieldName] = fieldValue;
    }

    return result;
  },
  {});
}

function getSegmentationValue() {
  return $('.js-order-cart-segmentation-field:checked').val();
}

function getFieldsErrors() {
  const SCHEMA = store.getFieldsValidationSchema();
  return Object.keys(SCHEMA).reduce(function (result, fieldKey) {
    const $field = $(ADDITIONAL_INFO_SELECTORS.fields[fieldKey]);
    const isPhone = fieldKey === 'phone';
    const value =
      !!$field.length && $.trim($field[isPhone ? 'cleanVal' : 'val']());

    if (SCHEMA[fieldKey] === 'required' && !value) {
      result.required = result.required
        ? result.required.concat(fieldKey)
        : [fieldKey];
    }

    return result;
  }, {});
}

function clearFieldError(element) {
  $(element)
    .closest(ADDITIONAL_INFO_SELECTORS.fieldGroup)
    .removeClass(CLASS_NAMES.error)
    .find(ADDITIONAL_INFO_SELECTORS.errorBlock)
    .empty()
    .hide();
}

function onFieldInput({target}) {
  clearFieldError(target);
}

function onChangeFields() {
  store.updateAdditionalInfo(_getComponentData());
}

function componentListener($component) {
  $component
    .on('change', getComponentFieldsSelector(), onChangeFields)
    .on('input', ADDITIONAL_INFO_SELECTORS.fields.message, onFieldInput)
    .on('input', ADDITIONAL_INFO_SELECTORS.fields.phone, onFieldInput)
    .on('updateOrdersRegions:selectric', onChangeFields);

  $component
    .closest(COMMON_SELECTORS.ordersCartItem)
    .on(
      'change',
      ADDITIONAL_INFO_SELECTORS.fields.segmentation,
      onChangeFields
    );
}

function checkValidity(order) {
  return !Object.keys(getFieldsErrors(order)).length;
}

function showValidationErrors(order) {
  const errors = getFieldsErrors(order);
  Object.keys(errors).forEach(function (errorType) {
    errors[errorType].forEach(function (fieldName) {
      showFieldError(fieldName, errorType);
    });
  });
}

function render($component, order) {
  const communicationType = getCommunicationType(getOrderSettings(order));
  const fields = COMMUNICATION_TYPE_TO_HELPER[communicationType](order);
  store.manageCommunicationType(communicationType);
  $component.html(getOrdersCartAdditionalInfoView());

  const cartItemId = selectCartItemByTarget($component).data('id');
  app.config.isMobile && initializeDeliveries(cartItemId);

  fields.auth && authComponent.render($component, fields.auth);
  toggleContactPhone({order});
  initPlugins($component);
  componentListener($component);
}

function showFieldError(fieldName, errorType) {
  const $fieldGroup = $(ADDITIONAL_INFO_SELECTORS.fields[fieldName])
    .closest(ADDITIONAL_INFO_SELECTORS.fieldGroup)
    .addClass(CLASS_NAMES.error);

  $fieldGroup
    .find(ADDITIONAL_INFO_SELECTORS.errorBlock)
    .text(
      $fieldGroup.data(
        ERRORS_MESSAGES_SCOPE_NAME.replace('${errorType}', errorType)
      )
    )
    .show();
}

export function unmount() {
  store.updateValidationSchema();
  $(COMMON_SELECTORS.additionalInfo).remove();
}

const additionalInfo = {
  render,
  unmount,
  showFieldError,
  checkValidity,
  showValidationErrors,
  clearFieldError,
};

export default additionalInfo;
