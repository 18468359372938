import _get from 'lodash.get';
import {AUTH_COMPONENT_CONTAINER} from './constants';
import store from '../../store';
import setAdditionalUserDataToProfile from '../../utils/set-additional-user-data-to-profile';
import {toggleContactPhone} from '../../../common/communication/helpers/toggle-contact-phone';

function processUserData($currentComponent) {
  const $authComponent = $currentComponent.find(AUTH_COMPONENT_CONTAINER);
  var $$processUser = $.Deferred();

  $authComponent.trigger('processUserData:authComponent', [$$processUser]);

  return $$processUser;
}

function unmount() {
  store.getStore().trigger('unmount:authComponent');
}

function getOnAuthComponentComplete() {
  return function (data) {
    unmount();
    setAdditionalUserDataToProfile(_get(data, 'user.phone'));
  };
}

function getOnAuthComponentUserSearch($component) {
  return function (authData) {
    if (store.manageCommunicationType() !== 'multiple') {
      return;
    }

    toggleContactPhone({
      order: store.getOrderByElement($component),
      multipleFieldType: authData.type,
    });
  };
}

const getAuthComponentOptions = ($component, settings) =>
  $.extend(
    true,
    {},
    app.config.ordersCart.authComponentOptions,
    {fields: settings},
    {
      onCompleted: getOnAuthComponentComplete(),
      onSearch: getOnAuthComponentUserSearch($component),
    }
  );

function renderAuthComponent($component, settings) {
  $component
    .find(AUTH_COMPONENT_CONTAINER)
    .trigger('render:authComponent', [
      getAuthComponentOptions($component, settings),
      store.getStore(),
    ]);
}

const authComponent = {
  processUserData,
  unmount,
  render: renderAuthComponent,
};

export default authComponent;
