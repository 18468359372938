// Компонент обработки оплаты с использованием яндекс кассы.
import getPaymentSlugByValue from '../../src/cart/utils/get-payment-slug-by-value';
import {toggleLoading} from '../../src/cart/orders-cart-container';
import getPaymentUrl from '../../src/cart/common/get-payment-url';

function onSuccessGettingUrl(response) {
  if (!(response && response['payment_url'])) {
    return;
  }

  window.open(response['payment_url']);
}

function payOnline(orderId, provider, onAfterSuccess) {
  toggleLoading(true);
  getPaymentUrl({orderId, paymentMethod: getPaymentSlugByValue(provider)})
    .then((response) => {
      if (!response.ok) {
        return;
      }
      response.json().then((response) => {
        onSuccessGettingUrl(response);
        onAfterSuccess && onAfterSuccess();
      });
    })
    .finally(toggleLoading);
}

export default payOnline;
