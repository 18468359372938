var Handlebars = require("../../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"old-browser-popup__info\">\n      <h2 class=\"old-browser-popup__title\">\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"old_browser.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":36}}}))
    + "\n      </h2>\n      <p class=\"old-browser-popup__description\">\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"old_browser.description_ie",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":45}}}))
    + "\n      </p>\n      <p class=\"old-browser-popup__description\">\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"old_browser.suggest_install",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":46}}}))
    + "\n      </p>\n    </div>\n    <ul class=\"old-browser-popup__browser-list\">\n      <li class=\"old-browser-popup__item old-browser-popup__item_chrome\">\n        <a\n          class=\"old-browser-popup__link-download\"\n          href=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"links") : depth0)) != null ? lookupProperty(stack1,"chrome") : stack1), depth0))
    + "\"\n          target='_blank'\n        >\n        </a>\n      </li>\n      <li class=\"old-browser-popup__item old-browser-popup__item_yandex\">\n        <a\n          class=\"old-browser-popup__link-download\"\n          href=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"links") : depth0)) != null ? lookupProperty(stack1,"yabrowser") : stack1), depth0))
    + "\"\n          target='_blank'\n        >\n        </a>\n      </li>\n      <li class=\"old-browser-popup__item old-browser-popup__item_firefox\">\n        <a\n          class=\"old-browser-popup__link-download\"\n          href=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"links") : depth0)) != null ? lookupProperty(stack1,"firefox") : stack1), depth0))
    + "\"\n          target='_blank'\n        >\n        </a>\n      </li>\n      <li class=\"old-browser-popup__item old-browser-popup__item_opera\">\n        <a\n          class=\"old-browser-popup__link-download\"\n          href=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"links") : depth0)) != null ? lookupProperty(stack1,"opr") : stack1), depth0))
    + "\"\n          target='_blank'\n        >\n        </a>\n      </li>\n    </ul>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <div class=\"old-browser-popup__info\">\n      <h2 class=\"old-browser-popup__title\">\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"old_browser.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":51,"column":36}}}))
    + "\n      </h2>\n      <p class=\"old-browser-popup__description\">\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"old_browser.description_other_browser",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":54,"column":56}}}))
    + "\n      </p>\n    </div>\n    <a class=\"old-browser-popup__link-update js-old-browser-popup__link-download\" target='_blank'>\n      <button class=\"aui-button aui-button_wide aui-button_color_orange\" type=\"button\">\n        "
    + alias2(__default(require("../../../../../../../../../static/blizko/local/node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"old_browser.update",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":59,"column":37}}}))
    + "\n      </button>\n    </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"old-browser-popup__content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ie") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":62,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});