import {DELIVERY_SELECTORS} from './constants';
import store from '../../store';
import {LOCALES} from '../../constants/common';

// перед изменением/удалением учитывать документацию!
// @see docs/frontend/delivery/README.md
export function highlightButton($orderCartItem, activeButtonSelector) {
  $orderCartItem.find(DELIVERY_SELECTORS.deliveryButton).removeClass('active');

  $orderCartItem.find(activeButtonSelector).addClass('active');
}

export function setDefaultAdditionalMessage(order) {
  if (!order) {
    return;
  }
  store.setAdditionalMessage(LOCALES.delivery.emptyMessage, order.id);
}

export function setAdditionalMessage(typeSlug, order) {
  const DELIVERY_TEMPLATES = store.getDeliveryTemplates();
  if (!DELIVERY_TEMPLATES) {
    return;
  }

  const additionalMessage = DELIVERY_TEMPLATES.messageTemplate({
    deliveryName: typeSlug === 'dellin' ? LOCALES.dellin.messageTitle : '',
    locales: LOCALES.delivery,
    delivery: order.delivery,
  });
  store.setAdditionalMessage(additionalMessage, order.id);
}
