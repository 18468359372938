import getPaymentProviders from '../../src/cart/utils/get-payment-providers';
import renderChooseOnlinePaymentModal from '../choose-online-payment/choose-online-payment';
import payOnline from './pay-online';
import renderPayKeeperPopup from './pay-keeper-popup';
import store from '../../src/cart/store';

/**
 * Модуль обработки онлайн оплаты
 */

function getOnlinePaymentType(payments) {
  const paymentProviders = getPaymentProviders(payments);

  const provider = paymentProviders.length ? paymentProviders[0] : null;
  return paymentProviders.length > 1 ? 'multiple' : provider;
}

/**
 * Определяет тип онлайн оплаты и производит необходимые для обработки этого типа действия.
 * @param {Object} detail Данные.
 * @param {Object} detail.order Данные заказа.
 * @param {Object} detail.renderResultPopup Функция отрисовки попапа успешного создания заказа.
 * @param {Function} payKeeperPopup Handlebars шаблон пейкипера.
 */
function handleOnlinePayment(detail, payKeeperPopup) {
  const order = detail.order,
    renderResultPopup = detail.renderResultPopup,
    ordersSettings = order.company && order.company['order_settings'];

  if (!(ordersSettings && ordersSettings.payments)) {
    return;
  }

  payKeeperPopup &&
    store.updateStore({
      projectConfig: {payKeeper: {templates: {payKeeperPopup}}},
    });
  const provider = getOnlinePaymentType(ordersSettings.payments);

  if (provider === 'multiple') {
    renderChooseOnlinePaymentModal(order, renderResultPopup);
    return;
  }
  if (provider === 'paykeeper') {
    renderPayKeeperPopup(order, renderResultPopup);
    return;
  }
  payOnline(order.id, provider, renderResultPopup);
}

export default handleOnlinePayment;
