import {
  onFailProductDelete,
  onSuccessProductDelete,
} from '../components/orders-item';
import toggleLoader from '../components/loader';
import {selectCartItemById} from '../utils/select-cart-item';
import api from '../constants/api';

function deleteProductItem({itemId, cartItemId}) {
  const cartItem = selectCartItemById(cartItemId);
  toggleLoader(cartItem, true);

  return fetch(api.getProductItemURL(itemId), {
    method: 'DELETE',
  })
    .then((response) => {
      const {ok, status} = response;
      if (!ok) {
        onFailProductDelete();
        return;
      }

      switch (status) {
        case 204:
          onSuccessProductDelete(null, itemId, cartItemId);
          return;
        default:
          response.json().then(({order}) => {
            onSuccessProductDelete(order, itemId, cartItemId);
          });
      }
    })
    .finally(() => toggleLoader(cartItem, false));
}

export default deleteProductItem;
