import {COMMUNICATION_TYPE_TO_HELPER} from '../constants/communication-type-to-helper';
import additionalInfo from '../../../cart/components/additional-info';
import {selectCartItemById} from '../../../cart/utils/select-cart-item';
import {ADDITIONAL_INFO_SELECTORS} from '../../../cart/constants/selectors';
import {getContactField} from './get-contact-field';
import store from '../../../cart/store';
import get from 'lodash.get';

/**
 * @see docs/frontend/common/communication.md
 */
export const toggleContactPhone = ({order, multipleFieldType}) => {
  const component = selectCartItemById(order.id)[0];
  const container =
    component &&
    component.querySelector(ADDITIONAL_INFO_SELECTORS.contactPhoneContainer);
  if (!container) {
    return;
  }

  const communicationType = store.manageCommunicationType();
  if (!communicationType) {
    return;
  }
  const fields = COMMUNICATION_TYPE_TO_HELPER[communicationType](
    order,
    multipleFieldType
  );

  const {contacts: show, contactsRequired: required} = fields;

  container.innerHTML = show
    ? getContactField(get(order, 'user.profile.contacts'), required)
    : '';

  store.updateValidationSchema({phone: required ? 'required' : ''});
  additionalInfo.clearFieldError(
    container.querySelector(ADDITIONAL_INFO_SELECTORS.fields.phone)
  );

  show && $doc.trigger('init:phoneMask');
};
