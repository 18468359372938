import api from '../constants/api';

function getOrderInfo({orderId, onSuccess, onFail, getParamsStr = ''}) {
  return fetch(api.getCartOrdersURL(orderId) + getParamsStr)
    .then((res) => res.json())
    .then(function (response) {
      if (!response.order) {
        onFail && onFail();
        return;
      }
      onSuccess && onSuccess(response);
    })
    .catch(function () {
      onFail && onFail();
    });
}

export default getOrderInfo;
