import {selectCartItemByTarget} from '../../../utils/select-cart-item';
import dellinCalculation from './orders_cart_delivery_dellin_calculation';
import {initSelect} from './orders_cart_delivery_dellin_city_select';
import popupTemplate from './templates/popup.handlebars';
import {
  updateDelivery,
  cancelDelivery,
} from '../../delivery/delivery-info-manager';
import _get from 'lodash.get';
import {LOCALES} from '../../../constants/common';
import {COMMON_SELECTORS} from '../../../constants/selectors';
import {DELIVERY_SELECTORS} from '../constants';
import store from '../../../store';

// перед изменением/удалением учитывать документацию!
// @see docs/frontend/delivery/README.md
let DELIVERY_TEMPLATES = {};
let _$currentContainer;

const _getDellinResponseData = (facade) => ({
  type_slug: 'dellin',
  price: facade.dellineResponse.price,
  derival_terminal_id: facade.dellineResponse.derival.terminals[0].id,
  arrival_city: facade.arrivalPoint,
  derival_city: facade.derivalPoint,
  period: facade.dellineResponse.time.value,
  description: facade.dellineResponse.arrival.city,
});

const getActionButtons = () =>
  DELIVERY_TEMPLATES.deliveryActions({
    buttons: [
      {
        actionClass: DELIVERY_SELECTORS.dellin.changeButton.replace('.', ''),
        actionText: LOCALES.dellin.recalcDelivery,
      },
      {
        actionClass: DELIVERY_SELECTORS.dellin.cancelButton.replace('.', ''),
        actionText: LOCALES.dellin.cancelDelivery,
      },
    ],
  });

const getDeliveryInfo = (order) => ({
  dataParams: JSON.stringify({
    arrival: _get(order, 'delivery.properties.arrival_city'),
    derival: _get(order, 'delivery.properties.derival_city'),
  }),
  deliveryDescription: _get(order, 'delivery.properties.description'),
  deliveryPrice: _get(order, 'delivery.formatted_price'),
  deliveryPeriod: _get(order, 'delivery.properties.period'),
  actions: getActionButtons(),
  locales: LOCALES.delivery,
  deliveryProvider: LOCALES.dellin.selectDellin,
  tip: app.i18n.delivery.selected.costTip,
});

const getDeliveryInfoTemplate = (order) =>
  DELIVERY_TEMPLATES.deliveryInfo(getDeliveryInfo(order));

const getButtonTemplate = () => {
  const template = DELIVERY_TEMPLATES.calculateButton;
  if (!template) {
    return '';
  }

  return template({
    text: LOCALES.dellin.selectDellin,
    jsClass: DELIVERY_SELECTORS.dellin.selectButton.replace('.', ''),
  });
};

function closePopup() {
  $(DELIVERY_SELECTORS.dellin.popup).dialog('close');
}

function onUpdateFail() {
  alert('Не удалось рассчитать доставку Деловые Линии');
}

function savePrice(facade) {
  if (facade.error) {
    alert(facade.error + '. Доставка отменена');
    onCancelDelivery();
    return;
  }

  updateDelivery({
    deliveryData: _getDellinResponseData(facade),
    orderId: _$currentContainer.data('id'),
    onAfterSuccess: closePopup,
    onAfterFail: onUpdateFail,
  });
}

function setActiveOrderContainer(facade) {
  _$currentContainer = facade.$container;
}

function onCancelDelivery(event) {
  const $container = event
    ? $(this).closest(COMMON_SELECTORS.ordersCartItem)
    : _$currentContainer;

  cancelDelivery($container.data('id'));
}

function showPopup() {
  const $loader = $('<div>').addClass('dellin-popup__loader');
  let $currentOrderItem;
  let order;

  if (!document.querySelector('.js-dellin-popup')) {
    createPopupContainer();
  }

  $currentOrderItem = selectCartItemByTarget($(this));

  order = store.getOrderByElement($currentOrderItem);

  setActiveOrderContainer({$container: $currentOrderItem});

  $(DELIVERY_SELECTORS.dellin.popup)
    .dialog({
      dialogClass: 'dellin-popup__dialog',
      width: 'auto',
      minHeight: 347,
      position: {my: 'center center-40'},
      modal: true,
      closeText: '',
      resizable: false,
      title: LOCALES.dellin.popupTitle,
      close: function () {
        dellinCalculation.resetState();
      },
    })
    .html($loader);

  showDellinForm(order);
}

function showDellinForm(order) {
  const popup = popupTemplate({specification: order.specification});
  $(DELIVERY_SELECTORS.dellin.popup).html(popup);

  initSelect({
    companyId: order.company.id,
    deliveryCityName: order.company['main_address'].city.name,
  });
}

function createPopupContainer() {
  const popupContainer = document.createElement('div');
  popupContainer.className = 'js-dellin-popup';
  document.body.append(popupContainer);
}

function addListeners() {
  $doc
    .on('click', DELIVERY_SELECTORS.dellin.cancelButton, onCancelDelivery)
    .on(
      'click',
      `${DELIVERY_SELECTORS.dellin.selectButton}, ${DELIVERY_SELECTORS.dellin.changeButton}`,
      showPopup
    );
}

export default {
  init: () => {
    DELIVERY_TEMPLATES = store.getDeliveryTemplates();
    addListeners();
    dellinCalculation.listeners();
  },
  setActiveOrderContainer,
  getButtonTemplate,
  getDeliveryInfoTemplate,
  getActionButtons,
  savePrice,
};
