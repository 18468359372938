import {selectCartItemByTarget} from '../../../utils/select-cart-item';
import {updateDelivery, cancelDelivery} from '../delivery-info-manager';
import popup from './templates/cdek-popup.handlebars';
import _get from 'lodash.get';
import {LOCALES} from '../../../constants/common';
import {DELIVERY_SELECTORS} from '../constants';
import store from '../../../store';

// перед изменением/удалением учитывать документацию!
// @see docs/frontend/delivery/README.md
let DELIVERY_TEMPLATES = {};

const openPopup = () =>
  document.querySelector('body').insertAdjacentHTML('beforeend', popup());

const closePopup = () =>
  document.querySelector(DELIVERY_SELECTORS.cdek.popup).remove();

const getGoods = (order) => {
  if (!order) {
    return;
  }

  // В заказе хранятся длины в метрах, а вес в килограммах. СДЕК принимает длины в сантиметрах, вес в килограммах.
  const {length, width, height, weight} = order.specification;
  return [
    {
      length: +length * 100,
      width: +width * 100,
      height: +height * 100,
      weight: +weight,
    },
  ];
};

const getPeriod = (term) => {
  if (typeof term !== 'string') {
    return;
  }
  const till = term && term.match(/\d+$/);
  return (till && till[0]) || 0;
};

const getDeliveryData = ({price, term, tarif, id, cityName, PVZ}) => ({
  price,
  type_slug: 'cdek',
  period: getPeriod(term),
  tariff_id: tarif,
  delivery_point: id,
  description: `${cityName}. ${PVZ.Address}.`,
});

const onFailDeliveryUpdate = () => {
  closePopup();
  alert(LOCALES.cdek.calculateError);
};

const onChoosePWZ = (data, orderId) => {
  if (!data || !data.price || !data.term) {
    return alert(LOCALES.cdek.cdekError);
  }
  updateDelivery({
    deliveryData: getDeliveryData(data),
    orderId,
    onAfterSuccess: closePopup,
    onAfterFail: onFailDeliveryUpdate,
  });
};

const getVolumeWeight = (parcelParams) =>
  (parcelParams.length * parcelParams.width * parcelParams.height) / 5000;

const getLimitsError = (parcelParams) => {
  const errors = [];

  if (!parcelParams) {
    return errors;
  }

  if (parcelParams.weight >= 30) {
    errors.push(LOCALES.cdek.weightError);
  }
  if (getVolumeWeight(parcelParams) >= 30) {
    errors.push(LOCALES.cdek.volumeWeightError);
  }
  const errorText = errors.join(', ');
  return errors.length
    ? `${LOCALES.cdek.errorHeader} ${errorText}. ${LOCALES.cdek.errorFooter}`
    : '';
};

const createWidget = (order, goods) => {
  const cityFrom = _get(
    order,
    'company.order_settings.deliveries.cdek.sender_city_name',
    ''
  );
  const apikey = _get(app, 'config.delivery.yandexMapApiKey');
  const companyId = _get(order, 'company.id');

  new ISDEKWidjet({
    link: 'forpvz',
    path: 'https://widget.cdek.ru/widget/scripts/',
    servicepath: `/api/v1/cdek/widgets/${companyId}`,
    apikey,
    country: 'Россия',
    cityFrom,
    goods,
    hidedelt: true,
    showErrors: false,
    onChoose: (data) => onChoosePWZ(data, order.id),
  });
};

const openWidget = (evt) => {
  const cartComponent = selectCartItemByTarget(evt.target);
  const order = store.getOrderByElement($(cartComponent));
  const goods = getGoods(order);

  const limitError = getLimitsError(goods[0]);
  if (limitError) {
    return alert(limitError);
  }

  openPopup();
  createWidget(order, goods);
};

const getActionButtons = (withUpdateButton) => {
  const template = DELIVERY_TEMPLATES.deliveryActions;
  if (!template) {
    return;
  }

  return template({
    buttons: [
      {
        actionClass: DELIVERY_SELECTORS.cdek.changeButton.replace('.', ''),
        actionText: withUpdateButton
          ? LOCALES.cdek.update
          : LOCALES.cdek.change,
      },
      {
        actionClass: DELIVERY_SELECTORS.cdek.cancelButton.replace('.', ''),
        actionText: LOCALES.cdek.cancel,
      },
    ],
  });
};

const getDeliveryInfo = (order) => ({
  deliveryDescription: _get(order, 'delivery.properties.description'),
  deliveryPrice: _get(order, 'delivery.formatted_price'),
  deliveryPeriod: _get(order, 'delivery.properties.period'),
  actions: getActionButtons(),
  locales: LOCALES.delivery,
  deliveryProvider: LOCALES.cdek.selectCdek,
  tip: app.i18n.delivery.selected.costTip,
});

const getDeliveryInfoTemplate = (order) => {
  const template = DELIVERY_TEMPLATES.deliveryInfo;
  if (!template) {
    return;
  }

  return template(getDeliveryInfo(order));
};

const getButtonTemplate = () => {
  const template = DELIVERY_TEMPLATES.calculateButton;
  if (!template) {
    return;
  }

  return template({
    text: LOCALES.cdek.selectCdek,
    jsClass: DELIVERY_SELECTORS.cdek.selectButton.replace('.', ''),
  });
};

const onCancelDelivery = (event) => {
  cancelDelivery(selectCartItemByTarget($(event.target)).data('id'));
};

function loadCdek() {
  if (window.ISDEKWidjet) {
    return;
  }
  var s = document.createElement('script');
  s.async = true;
  s.src = 'https://widget.cdek.ru/widget/widjet.js';
  s.id = 'ISDEKscript';
  document.body.appendChild(s);
}

function commonListener() {
  $doc
    .on(
      'click',
      `${DELIVERY_SELECTORS.cdek.selectButton}, ${DELIVERY_SELECTORS.cdek.changeButton}`,
      openWidget
    )
    .on('click', DELIVERY_SELECTORS.cdek.popupClose, closePopup)
    .on('click', DELIVERY_SELECTORS.cdek.cancelButton, onCancelDelivery);
}

export default {
  init: () => {
    loadCdek();
    commonListener();
    DELIVERY_TEMPLATES = store.getDeliveryTemplates();
  },
  getButtonTemplate,
  getDeliveryInfoTemplate,
  getActionButtons,
};
