// Контейнер корзины заказов. Служит для рендеринга корзины

import getMeasureUnits from './common/get-measure-units';
import getRegions from './common/get-regions';
import getCommonCartData from './common/get-common-cart-data';
import getCartItemsData from './common/get-cart-items-data';
import {ORDERS_CART_ITEMS_PER_PAGE} from './constants/common';
import {COMMON_SELECTORS} from './constants/selectors';
import store from './store';
import ordersCart from './components/orders-cart';
import cartLoader from './components/cart-loader';

const CLASS_NAMES = {
  loading: 'loading-icon',
};

const getNewFlatCartData = (cartItems) =>
  cartItems.reduce(function (result, cartItem) {
    const cartItemData = {};

    cartItemData.items = [].concat(cartItem.items);
    cartItemData.company = cartItem.company;
    result[cartItem.id] = cartItemData;

    return result;
  }, {});

const prepareFlatCartData = (cartItemsData) => ({
  flatCart: $.extend(
    {},
    store.getFlatCart(),
    getNewFlatCartData(cartItemsData.orders)
  ),
});

function isCartEmpty() {
  const storeData = store.getStoreData();

  return (
    ORDERS_CART_ITEMS_PER_PAGE >= storeData.cart['orders_count'] &&
    !Object.keys(storeData.flatCart).length
  );
}

function prepareDataToStore(cartData) {
  const commonCartData = cartData[0];
  const cartItemsData = cartData[1];
  const measureUnits = cartData[2];
  const regions = cartData[3];
  return $.extend(
    commonCartData,
    cartItemsData,
    {measureUnits},
    regions,
    prepareFlatCartData(cartItemsData),
    cartLoader.prepareLoaderData(commonCartData, cartItemsData)
  );
}

export function toggleLoading(show) {
  $(COMMON_SELECTORS.cartContainer).toggleClass(CLASS_NAMES.loading, !!show);
}

export function renderCart() {
  ordersCart.render(isCartEmpty());
}

export const getCartData = (mount) =>
  Promise.all([
    getCommonCartData(),
    getCartItemsData({mount}),
    getMeasureUnits(),
    getRegions(),
  ]);

export const showOrdersCartPage = () =>
  getCartData('mount')
    .then(prepareDataToStore)
    .then(store.updateStore)
    .then(renderCart)
    .then(function () {
      app.modules.markFavoriteProducts &&
        app.modules.markFavoriteProducts.update();
    });

export let projectModules = {};

function initCart({projectConfig, projectModules: modules}) {
  store.initStore(projectConfig);
  projectModules = Object.assign(
    {},
    {
      toggleQuantity: () => {},
      showResultPopup: () => {},
    },
    modules
  );
  showOrdersCartPage().finally(toggleLoading);
}

export default initCart;
