var Handlebars = require("../../../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dellin-popup\">\n  <div class=\"dellin-popup__offset-block\">\n    <span class=\"dellin-popup__logo\"></span>\n    <div class=\"dellin-popup__body\">\n      <div class=\"dellin-popup__row\">\n        <label>\n          <span class=\"dellin-popup__field-title clearfix\">\n            <span class=\"dellin-popup__field-title_left\">"
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.arrivalCity",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":86}}}))
    + "</span>\n          </span>\n          <input type=\"text\" class=\"dellin-popup__input js-dellin-popup__cities-select\" placeholder=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.arrivalCityPlaceholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":101},"end":{"line":10,"column":141}}}))
    + "\">\n          <span class=\"dellin-input__error js-dellin-popup__empty-arrival-city-error dn\">"
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.arrivalCityError",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":89},"end":{"line":11,"column":123}}}))
    + "</span>\n        </label>\n      </div>\n      <div class=\"clearfix\">\n        <div class=\"dellin-popup__half-row\">\n          <label>\n            <span class=\"dellin-popup__field-title clearfix\">\n              <span class=\"dellin-popup__field-title_left\">"
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.weightFieldLeft",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":59},"end":{"line":18,"column":92}}}))
    + "</span>\n              <span class=\"dellin-popup__field-title_right\">"
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.weightFieldRight",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":60},"end":{"line":19,"column":94}}}))
    + "</span>\n            </span>\n            <input\n              type=\"text\"\n              class=\"dellin-popup__input js-dellin-popup__item-weight\"\n              size=\"6\"\n              value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"specification") : depth0)) != null ? lookupProperty(stack1,"weight") : stack1), depth0))
    + "\"\n              disabled\n            >\n          </label>\n        </div>\n        <div class=\"dellin-popup__half-row\">\n          <label>\n            <span class=\"dellin-popup__field-title clearfix\">\n              <span class=\"dellin-popup__field-title_left\">"
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.volumeFieldLeft",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":59},"end":{"line":33,"column":92}}}))
    + "</span>\n              <span class=\"dellin-popup__field-title_right\">"
    + ((stack1 = __default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.volumeFieldRight",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":60},"end":{"line":34,"column":96}}})) != null ? stack1 : "")
    + "</span>\n            </span>\n\n            <input\n                type=\"text\"\n                class=\"dellin-popup__input js-dellin-popup__item-volume\"\n                size=\"6\"\n                value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"specification") : depth0)) != null ? lookupProperty(stack1,"volume") : stack1), depth0))
    + "\"\n                disabled\n            >\n          </label>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"dellin-popup__body\">\n    <div class=\"dellin-error__message js-dellin-error__message dn\"></div>\n\n    <div class=\"dellin-price__row js-dellin-price__row dn\">\n      <p>"
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.deliveryPrice",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":53,"column":9},"end":{"line":53,"column":40}}}))
    + "</p>\n      <strong class=\"dellin-delivery__price js-dellin-delivery__price\"></strong>\n    </div>\n    <div class=\"dellin-popup__offset-block\">\n      <div class=\"dellin-popup__row\">\n        <button\n          class=\"apress-button dellin-popup__submit js-dellin-popup__submit\"\n          data-calculate=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.submitCalc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":60,"column":26},"end":{"line":60,"column":54}}}))
    + "\"\n          data-loading=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.submitLoading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":61,"column":24},"end":{"line":61,"column":55}}}))
    + "\"\n          data-save=\""
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.submitSave",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":62,"column":21},"end":{"line":62,"column":49}}}))
    + "\"\n          type=\"button\"\n        >\n          "
    + alias2(__default(require("../../../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(alias1,"dellin.submitCalc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":65,"column":10},"end":{"line":65,"column":38}}}))
    + "\n        </button>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});