import addGetParamsToUrl from '../utils/add-get-params-to-url';
import partOrder from './part-order';
import {renderCart} from '../orders-cart-container';
import getItemsAttributes from '../utils/get-items-attributes';
import getTenderParams from '../utils/get-tender-params';
import isPaymentWithConfirmation from '../utils/is-payment-with-confirmation';
import setAdditionalUserDataToProfile from '../utils/set-additional-user-data-to-profile';
import {isUserSid} from '../utils/user-authorization-level';
import authComponent from './auth-component/auth-component';
import {AUTH_COMPONENT} from './auth-component/constants';
import additionalInfoMethods from './additional-info';
import {
  CART_ITEM_DATA_SCOPE_NAME,
  ADDITIONAL_INFO_DATA_SCOPE_NAME,
  LOCALES,
} from '../constants/common';
import deleteProductItem from '../common/delete-product-item';
import sendOrderItem from '../common/send-order-item';
import isOnlinePaymentAvailable from '../utils/is-online-payment-available';
import getMeasureUnitName from '../utils/get-measure-unit-name';
import getMinTotalMessage from '../utils/get-min-total-message';
import handleOnlinePayment from '../../../common/online-payment/online-payment';
import {
  selectCartItemById,
  selectCartItemByTarget,
} from '../utils/select-cart-item';
import discount from '../utils/order-items-discount';
import {initializeDeliveries} from '../components/delivery/delivery-initializer';
import onRenderOrdersItems from './orders-item';
import {
  COMMON_SELECTORS,
  ADDITIONAL_INFO_SELECTORS,
  TENDER_SELECTORS,
  FAST_ORDER_SELECTORS,
  DISCOUNT_SELECTORS,
} from '../constants/selectors';
import toggleLoader from './loader';
import store from '../store';
import {projectModules} from '../init-cart';
import _get from 'lodash.get';

// Компонент единицы корзины продавца/покупателя

let CLASS_NAMES = {
    showOnlinePaymentButton: 'show-online-payment-button',
    onlinePaymentButtonAwait: 'online-payment-button-await',
  },
  UPDATES_LOCKED = app.config.ordersUpdatesLocked,
  _userIsSid,
  sendBlizkoAnalytics;

function getOrderMessage({
  additionalInfo,
  orderFromFlatCart,
  onlinePay,
  $currentComponent,
}) {
  const additionalMessage = orderFromFlatCart.additionalMessage,
    onlinePaymentMessage = LOCALES.onlinePayment.message,
    paymentWithConfirmation =
      isPaymentWithConfirmation(orderFromFlatCart) &&
      $currentComponent.hasClass('online-payment-selected');
  let message = additionalInfo.message || '';

  if (additionalMessage) {
    message = message ? message + '\n' + additionalMessage : additionalMessage;
  }

  if (onlinePay || paymentWithConfirmation) {
    message = message
      ? message + '\n' + onlinePaymentMessage
      : onlinePaymentMessage;
  }
  return message;
}

function prepareCartItemData($currentComponent, onlinePay) {
  const componentData = $currentComponent.data(),
    id = componentData.id,
    additionalInfo = store.getStoreDataByScopeName(
      ADDITIONAL_INFO_DATA_SCOPE_NAME
    ),
    flatCart = store.getFlatCart(),
    orderFromFlatCart = flatCart[id],
    tenderProps = getTenderParams($currentComponent),
    message = getOrderMessage({
      additionalInfo,
      orderFromFlatCart,
      onlinePay,
      $currentComponent,
    }),
    promocode = store.getFlatCart()[id].promocode;

  const place = {
    customer_phone: additionalInfo.phone || app.config.currentUser.phone,
    customer_region_id: additionalInfo.region,
    items_attributes: getItemsAttributes(orderFromFlatCart.items),
    segmentation_purpose: additionalInfo.segmentation,
    online_payment: onlinePay,
    first_visit_url: $.cookie('first_visit_url'),
    promocode,
    delivery: orderFromFlatCart.delivery,
  };

  if (message) {
    place['messages_attributes'] = [{text: message}];
  }

  return {
    order_id: id,
    place,
    extra_params: {
      conversion_type: 'cart',
      user_agent: window.navigator.userAgent,
      create_tender: tenderProps.createTender,
      delay_tender_creation: tenderProps.delayTenderCreation,
    },
  };
}

function _formatDateTime(date) {
  return (
    date.toLocaleString('ru', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }) +
    ' в ' +
    date.toLocaleString('ru', {
      hour: 'numeric',
      minute: 'numeric',
    })
  );
}

function doesOrderContainOnlyDeletedItems(order) {
  return (
    order.items.filter(function (item) {
      return !item.subject;
    }).length === order.items.length
  );
}

function checkOrderCheckboxesVisibility(order) {
  const settings = order.company && order.company['order_settings'],
    withDeliveryOrOnlinePayment =
      settings && !!(settings.payments || settings.deliveries);

  return !withDeliveryOrOnlinePayment;
}

function _getItemIds(products) {
  return products.map((product) => product['subject_id']);
}

function _getFormattedItemsTotalOld(cartItemData, totalPrice) {
  return discount.getFormattedItemsTotalOld({
    items: cartItemData.items,
    itemsTotalPrice: totalPrice,
    delivery: cartItemData.delivery,
  });
}

function prepareDataToRender(cartItemData) {
  const storeData = store.getStoreData(),
    minTotal = getMinTotalMessage(cartItemData['price_validation']),
    paymentWithConfirmation = isPaymentWithConfirmation(cartItemData),
    itemsTotalPrice = cartItemData['items_total_price'],
    itemsTotalDiscount = discount.getFormattedOrderDiscount(cartItemData),
    hideDiscountBlock =
      !itemsTotalDiscount || itemsTotalPrice.type === 'agreed',
    itemsTotalOld =
      !hideDiscountBlock &&
      _getFormattedItemsTotalOld(cartItemData, itemsTotalPrice),
    itemsTotal = hideDiscountBlock
      ? cartItemData['formatted_total_with_delivery']
      : _getFormattedItemsTotalOld(cartItemData, itemsTotalPrice),
    updatesLocked =
      (minTotal && minTotal.blocked) ||
      UPDATES_LOCKED ||
      doesOrderContainOnlyDeletedItems(cartItemData),
    configs = storeData[CART_ITEM_DATA_SCOPE_NAME],
    onlinePayment = isOnlinePaymentAvailable(cartItemData),
    itemsRemainCount = cartItemData.items.length - 1;

  return $.extend(cartItemData, {
    itemsRemainCount,
    createdDateTime: _formatDateTime(new Date(cartItemData['created_at'])),
    measureUnitName: getMeasureUnitName(cartItemData.items[0]),
    updatesLockedClass: updatesLocked
      ? app.config.ordersCart.classNames.buttonDisabled
      : '',
    itemsData: JSON.stringify(storeData.flatCart[cartItemData.id].items),
    minTotalMessage: minTotal && minTotal.message,
    segmentation:
      app.config.ordersCart && app.config.ordersCart.segmentationPurposes,
    orderId: cartItemData.id,
    companyCheckboxVisible: checkOrderCheckboxesVisibility(cartItemData),
    companyCheckboxDisabled: doesOrderContainOnlyDeletedItems(cartItemData),
    paymentWithConfirmation,
    additionalClass: paymentWithConfirmation
      ? 'payment-with-confirmation__available'
      : '',
    firstVisitUrl: app.modules.customerOrdersUtils.getCookie('first_visit_url'),
    itemsTotalDiscount: itemsTotalPrice.type !== 'agreed' && itemsTotalDiscount,
    itemsTotalOld,
    itemsTotal,
    itemsTotalQuantityCount: _getItemsTotalQuantityCount(cartItemData.items),
    itemsMoreOneQuantityCount: cartItemData.items.length > 1,
    onlinePayment,
    isExactPrice: itemsTotalPrice.type === 'exact',
    companyHtmlUrl: _get(cartItemData, 'company.html_url'),
    companyName: _get(cartItemData, 'company.name'),
    sendTip:
      configs &&
      (onlinePayment && !paymentWithConfirmation
        ? configs['tip_online_pay']
        : configs.tip),
    productIds: _getItemIds(cartItemData.items),
    isMarketplace: cartItemData.company.marketplace,
  });
}

function _getItemsTotalQuantityCount(orders) {
  return orders.reduce((count, item) => Number(item.quantity) + count, 0);
}

function getCartItemsView(orders) {
  const ordersForRender = orders || store.getOrders();
  return ordersForRender.reduce(function ($result, cartItemData) {
    const $component = componentListener(getCartItemView(cartItemData));
    projectModules.promoCode &&
      projectModules.promoCode.init($component, cartItemData.id);
    return $result.append($component);
  }, $('<div>'));
}

function getCartItemView(cartItemData) {
  const templates = store.getCartItemTemplates(),
    template = templates && templates.cartItemTemplate;
  return $(template({data: prepareDataToRender(cartItemData)}));
}

function showAdditionalFields($component) {
  const order = store.getOrderByElement($component),
    showTenderControls =
      !isOnlinePaymentAvailable(order) &&
      !order.items.some(function (order) {
        return (
          order.properties &&
          order.properties['added_from_company_site'] === '1'
        );
      }),
    prepareSegmentation = _get(
      store.getStoreData(),
      'projectConfig.additionalInfo.prepareSegmentation'
    ),
    additionalInfo = store.getStoreDataByScopeName(
      ADDITIONAL_INFO_DATA_SCOPE_NAME
    );

  store.updateStore({
    customer_region_id: order['customer_region_id'],
    additionalInfo: Object.assign(additionalInfo, {
      region: order['customer_region_id'],
      phone: order['customer_phone'] || order.user.phone,
      showTenderControls: showTenderControls,
      segmentation: prepareSegmentation && prepareSegmentation(),
      orderId: order.id,
      order,
    }),
  });

  toggleOnlinePaymentButton($component, order);
  toggleComponentState($component, true);
  additionalInfoMethods.render(
    $component.find(COMMON_SELECTORS.ordersCartItemBottomContentArea),
    order
  );
}

function toggleComponentState($component, readyToSend) {
  $component
    .data({readyToSend: !!readyToSend})
    .toggleClass('orders-cart-item__ready-to-send', !!readyToSend);
}

function deleteCartItem(cartItemId) {
  Promise.all(
    store.getFlatCart()[cartItemId].items.map(function (orderItem) {
      return deleteProductItem({itemId: orderItem.id, cartItemId});
    })
  ).then(() => removeCartItemAndUpdateStoreData(cartItemId));
}

function filterFlatCartData(flatCartData, cartItemID) {
  delete flatCartData[cartItemID];
  return flatCartData;
}

function getDataToUpdateStoreData(cartItemID) {
  const storeData = store.getStoreData(),
    newOffset = --storeData.loader.offset,
    offset = newOffset > 0 ? newOffset : 0;

  return {
    loader: $.extend({}, storeData.loader, {offset}),
    flatCart: filterFlatCartData(storeData.flatCart, cartItemID),
    orders: storeData.orders.filter(({id}) => id !== cartItemID),
    removedCartItems: storeData.removedCartItems + 1,
  };
}

function onClickDeleteCartItem(event) {
  const $currentComponent = selectCartItemByTarget($(event.target)),
    cartItemID = $currentComponent.data('id');

  toggleLoader($currentComponent, true);
  deleteCartItem(cartItemID);
}

function sendCartItem($currentComponent, onlinePay) {
  const cartItemData = prepareCartItemData($currentComponent, onlinePay);

  sendOrderItem({
    orderId: cartItemData['order_id'],
    orderData: cartItemData,
  }).then((response) => {
    !response.errors
      ? onSuccessSendingCart(response, $currentComponent, onlinePay)
      : onSendCartItemFail(response, $currentComponent);
  });
}

function toggleOnlinePaymentButtonAbility($target, ability) {
  $target.toggleClass(
    app.config.ordersCart.classNames.buttonDisabled +
      ' ' +
      CLASS_NAMES.onlinePaymentButtonAwait,
    !ability
  );
}

const onAdditionalInfoInvalid = ($currentComponent, order) => {
  additionalInfoMethods.showValidationErrors(order);
  toggleOnlinePaymentButtonAbility(
    $currentComponent.find(COMMON_SELECTORS.onlinePaymentButton),
    true
  );
};

const canSendOrder = ($currentComponent, order) =>
  Promise.all([
    new Promise(function (resolve, reject) {
      if (!additionalInfoMethods.checkValidity(order)) {
        onAdditionalInfoInvalid($currentComponent, order);
        reject();
        return;
      }
      resolve();
    }),
    new Promise(function (resolve, reject) {
      if (!$currentComponent.find(AUTH_COMPONENT).length) {
        resolve();
        return;
      }
      authComponent
        .processUserData($currentComponent)
        .done((result) => {
          resolve(result);
        })
        .fail((error) => {
          onProcessUserDataFail($currentComponent, error);
          reject(error);
        });
    }),
  ]);

function getCheckoutUrl(isMarketplace) {
  const mainHost = isMarketplace ? `//${app.config.mainHost}` : location.origin;
  return `${mainHost}${location.pathname}/checkout/`;
}

function processSendItem(target, onlinePay) {
  const $currentComponent = selectCartItemByTarget(target),
    currentComponentData = $currentComponent.data(),
    order = store.getOrderByElement($currentComponent);

  $(COMMON_SELECTORS.cartItemJobError).addClass('dn');

  if (target.dataset.checkout) {
    const selected = partOrder.getSelectedOrderItemsIds($currentComponent);
    const url = addGetParamsToUrl(
      getCheckoutUrl(target.dataset.isMarketplace),
      {
        order_id: order.id,
        'selected_item_ids[]': selected,
        marketplace: target.dataset.isMarketplace,
      }
    );
    return location.assign(url);
  }

  if (!currentComponentData.readyToSend) {
    resetState();
    showAdditionalFields($currentComponent);
    sendBlizkoAnalytics && sendBlizkoAnalytics(2122);
  } else {
    toggleLoader($currentComponent, true);

    canSendOrder($currentComponent, order)
      .then(() => sendCartItem($currentComponent, onlinePay))
      .finally(() => {
        toggleLoader($currentComponent, false);
        toggleOnlinePaymentButtonAbility(
          $currentComponent.find(COMMON_SELECTORS.onlinePaymentButton),
          true
        );
      });
  }
}

const unmountCartAuthComponent = () => {
  const ordersCartAuthComponent =
    store.getStoreData().projectConfig.ordersCartAuthComponent;
  if (!ordersCartAuthComponent) {
    return;
  }
  ordersCartAuthComponent.unmount();
};

function onClickSendOrderItem(event, onlinePay) {
  processSendItem(event.target, onlinePay);
  unmountCartAuthComponent();
}

function onSuccessSendingCart(response, $currentComponent, onlinePay) {
  const order = response ? response.order : {};
  setAdditionalUserDataToProfile(
    $(ADDITIONAL_INFO_SELECTORS.fields.phone).val()
  );
  removeCartItemAndUpdateStoreData(order.id);

  if (onlinePay) {
    handleOnlinePayment({
      order,
      renderResultPopup: () => projectModules.showResultPopup(response),
    });
    sendBlizkoAnalytics && sendBlizkoAnalytics(2127);
  } else {
    projectModules.showResultPopup(response);
    sendBlizkoAnalytics
      ? sendBlizkoAnalytics(2123)
      : $doc.trigger('send:googleAnalytics', 'ORDERS-2464-7');
  }

  $doc.trigger('checkout:ordersItem', [order]);
}

function onProcessUserDataFail($currentComponent, error) {
  if (error === 'mergeUsersJobFailed') {
    $currentComponent.find(COMMON_SELECTORS.cartItemJobError).removeClass('dn');
  }
}

function findPhoneError(errors) {
  return (
    $.isArray(errors) &&
    errors.find(function (errorObj) {
      return errorObj['customer_phone'];
    })
  );
}

function onSendCartItemFail(response, $currentComponent) {
  if (response && response.errors) {
    if (findPhoneError(response.errors)) {
      additionalInfoMethods.showFieldError('phone', 'incorrect');
    }
  }
  toggleOnlinePaymentButtonAbility(
    $currentComponent.find(COMMON_SELECTORS.onlinePaymentButton),
    true
  );
}

function openAdditionalInfoOnRender($target) {
  const flatCart = store.getFlatCart(),
    cartItemsAmount = flatCart && Object.keys(flatCart).length;
  if (
    !(app.config.ordersCart.showAdditionalInfoOnRender && cartItemsAmount === 1)
  ) {
    return;
  }

  showAdditionalFields(selectCartItemByTarget($target));
  window.dataLayer &&
    dataLayer.push({
      event: 'VirtualPageview_' + app.config.gaIds['ORDERS-2507-1'],
    });
}

function renderOrderItems() {
  const $target = $(this);
  const cartItemId = selectCartItemByTarget($target).data('id');

  openAdditionalInfoOnRender($target);
  onRenderOrdersItems($target, cartItemId);
  !app.config.isMobile && initializeDeliveries(cartItemId);
}

function toggleOnlinePaymentButton($component, order) {
  const showButton = !!(
    order['items_total_price']['exact_value'] && isOnlinePaymentAvailable(order)
  );
  $component.toggleClass(CLASS_NAMES.showOnlinePaymentButton, showButton);
}

function toggleCartItemButtons($component, blockButtons) {
  const disabledClass = app.config.ordersCart.classNames.buttonDisabled;
  $component
    .find(COMMON_SELECTORS.cartItemButton)
    .toggleClass('js-orders-cart-item__send', !blockButtons)
    .toggleClass(disabledClass, blockButtons)
    .prop('disabled', blockButtons)
    .end()
    .find(COMMON_SELECTORS.onlinePaymentButton)
    .toggleClass(disabledClass, blockButtons)
    .end()
    .find(FAST_ORDER_SELECTORS.link)
    .toggleClass(disabledClass, blockButtons)
    .attr('disabled', blockButtons);
}

function onChangeCreateTenderCheckbox($this) {
  const $currentComponent = selectCartItemByTarget($this),
    $delayTenderCreationCheckbox = $currentComponent.find(
      TENDER_SELECTORS.delayCreationInput
    ),
    isChecked = $this.prop('checked'),
    delayTenderCreationIsChecked = $delayTenderCreationCheckbox.prop('checked');
  let $tenderCreationHint;

  $this.val(isChecked || delayTenderCreationIsChecked ? 1 : 0);
  $delayTenderCreationCheckbox.val(
    isChecked || !delayTenderCreationIsChecked ? 0 : 1
  );

  $currentComponent.find(TENDER_SELECTORS.delayCreationHintText).toggle();

  if (
    ($tenderCreationHint = $currentComponent.find(
      TENDER_SELECTORS.delayCreationHint
    )).length
  ) {
    const data = $tenderCreationHint.data();

    $tenderCreationHint.attr({
      'data-popup-hint': isChecked
        ? data['createTenderHint']
        : data['delayTenderHint'],
    });
  }
}

function onChangeDelayTenderCreationCheckbox() {
  const $this = $(this),
    $currentComponent = selectCartItemByTarget($this),
    isChecked = $this.prop('checked'),
    $createTenderCheckbox = $currentComponent.find(
      TENDER_SELECTORS.createInput
    );

  $this.val(isChecked && !$createTenderCheckbox.prop('checked') ? 1 : 0);
  $createTenderCheckbox.val(
    isChecked || $createTenderCheckbox.prop('checked') ? 1 : 0
  );
}

function onOnlinePaymentClick(event) {
  toggleOnlinePaymentButtonAbility($(event.target), false);
  onClickSendOrderItem(event, true);
}

function componentListener($component) {
  return $component
    .on('click', COMMON_SELECTORS.sendCartItem, onClickSendOrderItem)
    .on(
      'click',
      COMMON_SELECTORS.onlinePaymentButton +
        ':not(.' +
        app.config.ordersCart.classNames.buttonDisabled +
        ')',
      onOnlinePaymentClick
    )
    .on('click', COMMON_SELECTORS.cartItemDelete, onClickDeleteCartItem)
    .on('change', TENDER_SELECTORS.createInput, function () {
      app.config.ordersCart.hasDelayTenderCreationOption &&
        onChangeCreateTenderCheckbox($(this));
    })
    .on(
      'change',
      TENDER_SELECTORS.delayCreationInput,
      onChangeDelayTenderCreationCheckbox
    );
}

function removeCartItemAndUpdateStoreData(cartItemId) {
  store.updateStore(getDataToUpdateStoreData(cartItemId), 'updateCounter');
  store.resetStoreDataByType('additionalInfo');
  selectCartItemById(cartItemId).remove();
  renderCart();
}

function resetState() {
  const cartItem = $(COMMON_SELECTORS.ordersCartItem);
  toggleComponentState(cartItem);
  $(COMMON_SELECTORS.ordersCartItem)
    .find(COMMON_SELECTORS.cartItemToggleContentButton)
    .remove();

  authComponent.unmount(cartItem);
  additionalInfoMethods.unmount();
}

function renderCartItems(orders) {
  sendBlizkoAnalytics =
    app.modules.analytics && app.modules.analytics.sendExternalAnalytics;
  _userIsSid = isUserSid();

  $(COMMON_SELECTORS.ordersCartContentArea)
    .append(getCartItemsView(orders))
    .find(COMMON_SELECTORS.emptyContentArea)
    .each(renderOrderItems);
}

function updateQuantityTotalProduct($component, measureUnitName) {
  if (measureUnitName) {
    $component.find(COMMON_SELECTORS.totalProductsUnit).html(measureUnitName);
  }
}

function updateTotal(data, hideTotal) {
  const $currentComponent = selectCartItemById(data.id),
    componentOrderData = store.getOrderById(data.id),
    minTotal = getMinTotalMessage(data['price_validation']),
    blockButtons = !!(
      (minTotal && minTotal.blocked) ||
      UPDATES_LOCKED ||
      hideTotal ||
      doesOrderContainOnlyDeletedItems(data)
    ),
    measureUnitName = getMeasureUnitName(data.items[0]),
    promoCodeActive = data['promocode_discount'],
    showDiscountBlock =
      discount.showDiscountBlock(data.items, $currentComponent) ||
      promoCodeActive,
    itemsTotalDiscount = discount.getFormattedOrderDiscount(
      data,
      $currentComponent
    ),
    itemsTotalPrice = data['items_total_price'],
    hideDiscountBlock =
      !!blockButtons ||
      !showDiscountBlock ||
      !itemsTotalDiscount ||
      itemsTotalPrice.type === 'agreed',
    itemsTotalOld =
      !hideDiscountBlock && _getFormattedItemsTotalOld(data, itemsTotalPrice),
    itemsTotal = hideDiscountBlock
      ? data['formatted_total_with_delivery']
      : _getFormattedItemsTotalOld(data, itemsTotalPrice),
    itemsTotalQuantityCount = _getItemsTotalQuantityCount(data['items']),
    itemsMoreOneQuantityCount = data.items.length > 1,
    discountLabel = promoCodeActive
      ? _get(app, 'i18n.discount.labelWithPromoCode')
      : _get(app, 'i18n.discount.label');

  componentOrderData.total = data['formatted_total_with_discount_and_delivery'];
  toggleOnlinePaymentButton($currentComponent, data);
  toggleCartItemButtons($currentComponent, blockButtons);
  !itemsMoreOneQuantityCount &&
    updateQuantityTotalProduct($currentComponent, measureUnitName);

  $currentComponent
    .find(COMMON_SELECTORS.cartItemTotalBlock)
    .toggleClass('dn', !!hideTotal)
    .end()
    .find(COMMON_SELECTORS.cartItemTotal)
    .html(data['formatted_total_with_discount_and_delivery'])
    .end()
    .find(DISCOUNT_SELECTORS.totalDiscount)
    .html(itemsTotalDiscount)
    .end()
    .find(DISCOUNT_SELECTORS.totalDiscountLabel)
    .html(discountLabel)
    .end()
    .find(DISCOUNT_SELECTORS.cartItemTotalOld)
    .html(itemsTotalOld)
    .toggleClass('dn', hideDiscountBlock)
    .end()
    .find(DISCOUNT_SELECTORS.totalDiscountBlock)
    .toggleClass('dn', hideDiscountBlock)
    .end()
    .find(COMMON_SELECTORS.cartItemTotalDescription)
    .toggleClass('dn', !!hideTotal)
    .end()
    .find(COMMON_SELECTORS.totalProductsCart)
    .html(itemsTotalQuantityCount)
    .end()
    .find(DISCOUNT_SELECTORS.totalOlderPrice)
    .html(itemsTotal)
    .end()
    .find(COMMON_SELECTORS.cartItemTotalWarningWrapper)
    .toggleClass('dn', !minTotal)
    .end()
    .find(COMMON_SELECTORS.cartItemTotalWarning)
    .html(minTotal && minTotal.message)
    .toggleClass('dn', !minTotal);
}

export default {
  remove: removeCartItemAndUpdateStoreData,
  resetState,
  renderCartItems,
  updateTotal,
};
