import iframeTemplate from './templates/iframe.handlebars';

app.modules.watchingVideo = ((self = {}) => {
  function _handleClick(event) {
    const videoButtonEl = event.target.closest('.js-watching-video');
    if (!videoButtonEl) {
      return;
    }

    const iframeSrc = videoButtonEl.dataset.src.match(/src="(.*?)"/)[1];
    const htmlEl = document.createElement('div');
    htmlEl.innerHTML = iframeTemplate({src: iframeSrc});

    videoButtonEl.after(htmlEl.firstChild);
    videoButtonEl.remove();
  }

  function _listener() {
    document.addEventListener('click', _handleClick);
  }

  self.load = _listener;
  return self;
})(app.modules.watchingVideo);
