/**
 * Кейсы:
 * Если выбрано отложенное создание спроса, то { createTender: 1, delayTenderCreation: 1 } // возможно только на ПЦ
 * Если выбраны обе галочки, то { createTender: 1, delayTenderCreation: 0(это не опечатка) } // возможно только на ПЦ
 * Если НЕ выбраны обе галочки, то { createTender: 0, delayTenderCreation: 0 }
 * Если выбрано только создание спроса, то { createTender: 1, delayTenderCreation: 0 }
 * @param $currentComponent - Компонент с классом '.js-orders-cart-item' с которым ведется работа на данный момент.
 */
const getTenderParams = ($currentComponent) => {
  const tenderProps = {createTender: 0, delayTenderCreation: 0},
    checkedCreationInput = $currentComponent.find(
      '.js-orders-cart-tender__create-input:checked'
    ),
    checkedDelayTenderInput = $currentComponent.find(
      '.js-orders-cart__delay-tender-creation-input:checked'
    );

  if (checkedDelayTenderInput.length) {
    // Если выбрано отложенное создание спроса, то createTender должен быть 1, иначе не создастся никакая заявка спроса.
    tenderProps.createTender = 1;
    // Если при этом выбрано создание спроса, то delayTenderCreation должен быть 0
    tenderProps.delayTenderCreation = checkedCreationInput.length ? 0 : 1;
  } else {
    tenderProps.createTender =
      checkedCreationInput.prop('type') === 'radio'
        ? // Близко десктоп содержит радиобаттоны templates/blizko_/orders_cart/_tender_control
          // из-за этого приходится проверять value
          checkedCreationInput.val()
        : checkedCreationInput.length;
  }
  return tenderProps;
};

export default getTenderParams;
