// перед изменением/удалением учитывать документацию!
// @see docs/frontend/delivery/README.md
import dellin from './dellin/orders_cart_delivery_dellin_price-manager';
import boxberry from './boxberry/boxberry';
import cdek from './cdek/cdek';

export const DELIVERY_SELECTORS = {
  dellin: {
    container: '.js-delivery__dellin-container',
    popup: '.js-dellin-popup',
    selectButton: '.js-select-dellin',
    changeButton: '.js-dellin-change-delivery',
    cancelButton: '.js-dellin-cancel-delivery',
  },
  boxberry: {
    container: '.js-delivery__boxberry-container',
    selectButton: '.js-boxberry-select',
    changeButton: '.js-order-cart-boxberry-change',
    cancelButton: '.js-order-cart-boxberry-cancel',
  },
  cdek: {
    container: '.js-delivery__cdek-container',
    selectButton: '.js-cdek-select',
    changeButton: '.js-cdek-delivery__change',
    cancelButton: '.js-cdek-delivery__cancel',
    popup: '.js-cdek-popup__wrap',
    popupClose: '.js-cdek-popup__close',
  },
  deliveryInfo: '.js-orders-cart-delivery-info',
  deliveriesItems: '.js-deliveries-items',
  deliveryButton: '.js-delivery-buttons__button',
  deliveryItemsOpened: 'orders-cart-delivery__items_opened',
};

export const DELIVERIES = ['boxberry', 'dellin', 'cdek'];
export const HANDLERS = {
  dellin,
  boxberry,
  cdek,
};
