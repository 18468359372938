// Сохраняет не заполненные у пользователя данные в профиль.

import {ADDITIONAL_INFO_SELECTORS} from '../constants/selectors';
import store from '../store';
import updateUser from '../common/update-user';
import _get from 'lodash.get';

function setAdditionalUserDataToProfile(phone) {
  const profileContact = _get(app, 'config.currentUser.contacts');
  const contact =
    profileContact || $(ADDITIONAL_INFO_SELECTORS.fields.phone).val() || phone;
  const profileCityId = _get(app, 'config.currentUser.cityId');

  const regionId = _get(
    store.getStoreDataByScopeName('additionalInfo'),
    'region'
  );
  const cityId =
    _get(
      store.getStoreData().regions.filter(({id}) => id === +regionId),
      '[0].capital_id'
    ) || _get(app, 'config.currentRegionCapitalId');

  if (
    !(contact || cityId) ||
    (profileContact === contact && profileCityId === cityId)
  ) {
    return;
  }

  updateUser({phone: contact, cityId});
}

export default setAdditionalUserDataToProfile;
