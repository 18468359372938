import cartItem from '../components/orders-cart-item';
import {getCartData} from '../orders-cart-container';
import pluralizeProducts from '../utils/pluralize-products';
import {
  LOADER_SCOPE_NAME,
  LOCALES,
  ORDERS_CART_ITEMS_PER_PAGE,
} from '../constants/common';
import store from '../store';
import {COMMON_SELECTORS, LOADER_SELECTORS} from '../constants/selectors';
import _get from 'lodash.get';
// Компонент-загрузчик заказов

const CLASS_NAMES = {
  disabledItem: 'disabled',
};

const getLoaderTip = (cartItemsRemain) => {
  const more = LOCALES.remainingCount.more;
  const from = LOCALES.remainingCount.from;

  if (!more && !from) {
    return '';
  }

  const products = pluralizeProducts(cartItemsRemain);
  const sellers = app.modules.applicationUtils.pluralize(cartItemsRemain, [
    LOCALES.remainingCount.sellers.one,
    LOCALES.remainingCount.sellers.few,
    LOCALES.remainingCount.sellers.many,
  ]);
  return `${more} ${products} ${from} ${sellers}`;
};

function getCartItemsRemain(totalCartItemsCount) {
  const loader = store.getStoreDataByScopeName(LOADER_SCOPE_NAME);
  const cartItemsCount =
    (loader.cartItemsRemain || totalCartItemsCount) -
    ORDERS_CART_ITEMS_PER_PAGE;

  return cartItemsCount < 0 ? 0 : cartItemsCount;
}

function getOrderItemsRemain(totalOrderItemsCount, newOrderItemsCount) {
  const loader = store.getStoreDataByScopeName(LOADER_SCOPE_NAME);
  const orderItemsRemain =
    (loader.orderItemsRemain || totalOrderItemsCount) - newOrderItemsCount;

  return orderItemsRemain < 0 ? 0 : orderItemsRemain;
}

const getNewOrderItemsCount = (newCartItems) =>
  newCartItems.reduce(function (result, cartItem) {
    return result + cartItem.items.length;
  }, 0);

function prepareLoaderData(commonCartData, cartItemsData) {
  const totalOrderItemsCount = commonCartData.cart['items_count'];
  const totalCartItemsCount = commonCartData.cart['orders_count'];
  const orderItems = cartItemsData.orders;
  const loader = store.getStoreDataByScopeName(LOADER_SCOPE_NAME);

  return {
    loader: $.extend({}, loader, {
      orderItemsRemain: getOrderItemsRemain(
        totalOrderItemsCount,
        getNewOrderItemsCount(orderItems)
      ),
      cartItemsRemain: getCartItemsRemain(parseInt(totalCartItemsCount)),
    }),
  };
}

const getLoaderData = () => {
  const {cartItemsRemain} = store.getStoreDataByScopeName(LOADER_SCOPE_NAME);

  return {
    cartItemsRemain,
    loaderTip: getLoaderTip(cartItemsRemain),
  };
};

const getOrdersCartLoaderView = () => {
  const loaderData = getLoaderData();
  const loaderTemplate = _get(
    store.getStore().data(),
    'projectConfig.ordersCart.templates.loaderTemplate'
  );
  return loaderData.cartItemsRemain ? loaderTemplate({data: loaderData}) : '';
};

function getDataToUpdateLoader() {
  const loader = store.getStoreDataByScopeName(LOADER_SCOPE_NAME);

  return {
    loader: Object.assign(loader, {
      offset: loader.offset + loader.limit,
    }),
  };
}

const getNewFlatCartData = (orders) =>
  orders.reduce(function (result, cartItem) {
    const cartItemData = {};

    cartItemData.items = [].concat(cartItem.items);
    cartItemData.company = cartItem.company;
    result[cartItem.id] = cartItemData;

    return result;
  }, {});

const prepareOrdersData = (ordersToAppend) => {
  let orders = store.getOrders();
  ordersToAppend.forEach(function (orderToAppend) {
    if (orders.find((order) => order.id === orderToAppend.id)) {
      return;
    }
    orders.push(orderToAppend);
  });
  return {
    orders,
    flatCart: $.extend({}, store.getFlatCart(), getNewFlatCartData(orders)),
  };
};

const listener = (component) => {
  const controlButton = component.querySelector(LOADER_SELECTORS.controller);
  controlButton && controlButton.addEventListener('click', onClickController);
};

function renderOrdersCartLoader() {
  const component = document.querySelector(
    COMMON_SELECTORS.ordersCartBottomContentArea
  );

  if (!component) {
    return;
  }
  component.innerHTML = getOrdersCartLoaderView();
  listener(component);
}

function onClickController(event) {
  event.target.classList.add(CLASS_NAMES.disabledItem);

  store.updateStore(getDataToUpdateLoader());
  getCartData('mount').then((cartData) => {
    if (!cartData) {
      return;
    }
    const loaderData = prepareLoaderData(cartData[0], cartData[1]);
    const orders = prepareOrdersData(cartData[1].orders);
    store.updateStore(Object.assign(loaderData, orders));
    cartItem.renderCartItems(cartData[1].orders);
    renderOrdersCartLoader();
  });
}

export default {
  renderOrdersCartLoader,
  prepareLoaderData,
};
