import api from '../constants/api';

function getPaymentUrl({orderId, paymentMethod}) {
  return fetch(api.getPaymentUrl(orderId), {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    method: 'POST',
    body: JSON.stringify({
      payment_method: paymentMethod,
      page_view: app.config.isMobile && 'MOBILE',
    }),
  });
}

export default getPaymentUrl;
