/**
 * Добавляет в цену пробелы между каждыми 3 знаками.
 * @param {string} price
 * @return {string} formatPriceWithSpaces('8700000') => '8 700 000'
 */
const formatPriceWithSpaces = (price) => {
  if (typeof price !== 'string') {
    return '';
  }
  const splitPrice = String(price).split('.');

  const formattedPrice = splitPrice[0]
    .split('')
    .reverse()
    .map((el, index) => el + (index > 0 && index % 3 === 0 ? ' ' : ''))
    .reduceRight((prevVal, currVal) => prevVal + currVal);

  return splitPrice[1] ? `${formattedPrice}.${splitPrice[1]}` : formattedPrice;
};

export default formatPriceWithSpaces;
