// Модуль для отправки конверсионных событий с товарных листингов

// Требуется конфиг: app.config.loggingData
// - requestUrl - адрес запроса
// - commonPayload - общие данные для всех событий на странице (не обязательный)
// - itemsPayload - хэш с данными для каждого элемента (в качестве ключей id элементов)

// Варианты использования:
// - отправка события по клику:
// элемент js-conversion-event с дата атрибутами event-type и event-item

// - отправка событий после открытия страницы:
// массив объектов app.config.initialConversionEvents со свойствами type, productId

// - отправка события из других модулей:
// событие conversionEventLog:send с аргументом [type, productId, userId(не обязательно)]

// Варианты сбора payload конверсионного элемента:
// - из глобального объекта app.config.loggingData.itemsPayload
// - из data атрибута data-conversion -item родительского элемента с классом js-conversion-target

import {registerWithSID} from './register_with_sid';

app.modules.conversionEventLog = (function (self) {
  function _prepareEventConfigAndSendConversion(event, eventData) {
    eventData = eventData || {};
    event = event || {};

    const conversionElementEvent = event.target || this;
    const itemsPayload = app.config.loggingData.itemsPayload || {};
    const subjectData = _getSubjectData(eventData, $(conversionElementEvent));
    const subjectPayload = itemsPayload[subjectData.subjectId] || _setTargetItem(itemsPayload, conversionElementEvent);

    if (!subjectPayload) {
      return;
    }

    if (_checkSendPayloadTypeSubject(subjectPayload, subjectData.eventType)) {
      return;
    }

    const conversionData = _prepareSubjectConversionData(
      subjectData,
      subjectPayload,
      eventData
    );

    _sendEventData(conversionData);

    if (_checkYkropConfig()) {
      _sendEventData({
        ...conversionData,
        sign: app.config.loggingData.signPayload,
      });
    }

    _markSendPayloadTypeSubject(subjectPayload, subjectData.eventType);
  }

  function _setTargetItem(itemsPayload, element) {
    const conversionTarget = element.closest('.js-conversion-target');

    if (!conversionTarget) return;

    try {
      const elementConversion = JSON.parse(conversionTarget.dataset.conversionItem);
      itemsPayload[element.dataset.eventItem] = elementConversion;

      return elementConversion;
    } catch (error) {
      return null;
    }
  }

  function _checkYkropConfig() {
    return (
      app.config.loggingData.sendToYkrop && app.config.loggingData.signPayload
    );
  }

  function _markSendPayloadTypeSubject(subjectPayload, eventType) {
    subjectPayload.sended.push(eventType);
  }

  function _prepareSubjectConversionData(data, payload, eventData) {
    const userId = eventData.userId ? {user_id: eventData.userId} : {};
    const commonPayload = app.config.loggingData.commonPayload || {};

    return {
      conversion_type: data.eventType,
      subject_id: data.subjectId,
      subject_type: data.subjectType,
      payload: JSON.stringify({
        ...commonPayload,
        ...payload,
        ...userId,
      }),
    };
  }

  function _sendEventData(data) {
    fetch(_getUrlConversionSend(data.sign), {
      method: 'POST',
      body: _prepareAndGetRequestBody(data),
    }).catch((error) => console.error(error));
  }

  function _getUrlConversionSend(ykropProperty) {
    return ykropProperty ?
      app.config.loggingData.sendToYkrop : app.config.loggingData.requestUrl;
  }

  function _prepareAndGetRequestBody(body) {
    const formData = new FormData();

    for (const name in body) {
      formData.append(name, body[name]);
    }

    return formData;
  }

  function _checkSendPayloadTypeSubject(subject, type) {
    subject.sended = subject.sended || [];
    return subject.sended.includes(type);
  }

  function _getSubjectData(eventData, subject) {
    return {
      eventType: eventData.type || subject.data('eventType') || 0,
      subjectId: eventData.productId || subject.data('eventItem') || 0,
      subjectType:
        eventData.subjectType || subject.data('eventSubjectType') || 'Product',
    };
  }

  function _checkUserSigned(event, eventData) {
    if (eventData) {
      return;
    }
    if (!app.config.isUserSigned) {
      try {
        registerWithSID().then(() => {
          const config = app.config;
          config.loggingData.commonPayload['user_id'] = config.currentUser.id;
          _prepareEventConfigAndSendConversion.call(this);
        });
      } catch (e) {
        _prepareEventConfigAndSendConversion.call(this);
      }
    } else {
      _prepareEventConfigAndSendConversion.call(this);
    }
  }

  function _sendInitialEvents() {
    if (!app.config.initialConversionEvents) {
      return;
    }

    app.config.initialConversionEvents.forEach(function (eventData) {
      _prepareEventConfigAndSendConversion(null, eventData);
    });
  }

  function _addListener() {
    $doc
      .on('click', '.js-conversion-event', _checkUserSigned)
      .on('conversionEventLog:send', _prepareEventConfigAndSendConversion);
  }

  function _init() {
    _addListener();
    _sendInitialEvents();
  }

  self.load = app.config.loggingData && _init;
  return self;
})(app.modules.conversionEventLog || {});
