import formatPriceWithSpaces from './format_price_with_spaces';

/**
 * @param {Object} order
 * @param {Number} order.promocode_discount.
 * @param {String} order.promocode_currency_title.
 * @param {String} order.formatted_total.
 * @param {String} order.items_total_price.type
 * @param {String} order.items_total_price.currency_title
 * @param {Number} itemsDiscount товарная скидка
 * @return {string} Форматированная сумма скидки по промокоду. Учитывает форматы цен: от, от до, до, точная.
 */
const getOrderDiscount = (
  {
    promocode_discount: promoDiscount,
    promocode_currency_title: currency,
    formatted_total: formattedTotal,
    items_total_price: {type: priceType, currency_title: orderCurrency} = {},
  } = {},
  itemsDiscount
) => {
  if (
    ['null', 'agreed'].includes(priceType) ||
    !(promoDiscount || itemsDiscount)
  ) {
    return '';
  }

  if (currency === '%') {
    // Регулярка находит числа в цене с учетом пробелов, например: 1 000, 100 000 и т.п.
    const regex = /\d+(\s?\d+)+/g;
    const prices = formattedTotal.match(regex);
    return prices.reduce((total, price) => {
      const priceNumber = Number(price.replace(/\s/g, ''));
      return total.replace(
        price,
        formatPriceWithSpaces(
          String(priceNumber * (promoDiscount / 100) + itemsDiscount)
        )
      );
    }, formattedTotal);
  }
  return `${formatPriceWithSpaces(String(promoDiscount + itemsDiscount))} ${
    currency || orderCurrency
  }`;
};

export default getOrderDiscount;
