// Модуль для изменения маркетинговых ссылок в футере на региональные
// js-dropdown-list-marketing - находится в ТЗ PortalFooter/MobileCommerce,
// PortalFooter/Commerce, PortalFooter/AmpCommerce

app.modules.changeFooterSellersLinks = (function (self) {
  const _marketingLinks = document.querySelectorAll(
    '.js-dropdown-list-marketing'
  );
  const _socialLinks = document.querySelector('.js-footer-social-link');

  function _init() {
    _isShowSocialLinks() && _showSocialLinks();
    _marketingLinks && _changeSellersLinks();
  }

  function _isShowSocialLinks() {
    return app.config.isShowSocialButtons && _socialLinks;
  }

  function _showSocialLinks() {
    _socialLinks.classList.remove('pmf-social-links');
  }

  function _changeSellersLinks() {
    _marketingLinks.forEach((link) => {
      const footerItemSellersLink = new URL(link.href);
      link.href = _createRegionLink(footerItemSellersLink);
    });
  }

  function _createRegionLink(link) {
    return new URL(location.origin + link.pathname + link.search + link.hash);
  }

  self.load = function () {
    !location.pathname.includes('stats') && _init();
  };

  return self;
})(app.modules.changeFooterSellersLinks || {});
