import dellinCalculation from './orders_cart_delivery_dellin_calculation';

// перед изменением/удалением учитывать документацию!
// @see docs/frontend/delivery/README.md
const API_ROUTE =
    '/api/v1/dellin/cities?company_id=:company_id&dellin[q]=:user_input&dellin[limit]=10',
  INPUT_SELECTOR = '.js-dellin-popup .js-dellin-popup__cities-select';

let _$currentComponent = null,
  _currentCompanyId = null;

function _findCities(input, callback) {
  return _sendApiRequest(input.term, function (response) {
    _onCitiesFound(response, callback);
  });
}

function _sendApiRequest(input, onSuccess) {
  $.ajax({
    method: 'GET',
    url: encodeURI(
      API_ROUTE.replace(':company_id', _currentCompanyId).replace(
        ':user_input',
        input
      )
    ),
    success: onSuccess,
  });
}

function _onCitiesFound(response, callback) {
  callback(
    response.map(function (item) {
      return item.aString;
    })
  );
  dellinCalculation.setNewCitiesVariants({
    companyId: _currentCompanyId,
    cities: response,
  });
}

export function initSelect(facade) {
  const $submit = $('.js-dellin-popup__submit');
  $submit.html($submit.data('loading')).attr('disabled', true);

  _currentCompanyId = facade.companyId;
  _$currentComponent = $(INPUT_SELECTOR)
    .autocomplete({source: _findCities})
    .on('click', function () {
      !$('.js-dellin-popup__empty-arrival-city-error').hasClass('dn') &&
        $(this).autocomplete('search');
    });

  return _sendApiRequest(facade.deliveryCityName, function (response) {
    if (!response.length) {
      return;
    }
    $submit.html($submit.data('calculate')).attr('disabled', false);
    dellinCalculation.setNewDerivalAddress({derival: response[0].code});
  });
}
