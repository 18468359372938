import api from '../constants/api';
import _get from 'lodash.get';

const updateUser = ({phone, cityId}) => {
  const profileAttrs = {};
  phone && (profileAttrs.contacts = phone);
  cityId && (profileAttrs['city_id'] = cityId);

  return fetch(api.getUpdateUserUrl(app.config.currentUser.id), {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    method: 'PUT',
    body: JSON.stringify({profile_attributes: profileAttrs}),
  }).then((response) =>
    response.json().then((data) => {
      const updated = {};
      const userPhone =
        _get(data, 'user.profile.contacts') || _get(data, 'user.phone');
      const userCity = _get(data, 'user.profile.city_id');
      userPhone && (updated.contacts = userPhone);
      userCity && (updated.cityId = userCity);
      app.config.currentUser = Object.assign(
        {},
        app.config.currentUser,
        updated
      );
    })
  );
};

export default updateUser;
