import loaderTemplate from './templates/loader.handlebars';

const selector = '.js-loader';

function toggleLoader($target, display) {
  if (display) {
    $target.prepend(loaderTemplate());
  } else {
    const loader = document.querySelector(selector);
    loader && loader.remove();
  }
}

export default toggleLoader;
