// Модуль для определения информации о браузере пользователя,
// если версия браузера устарела (или это IE), показываем модалку
// с предложением скачать/установить актуальную версию

import oldBrowserTemplate from './templates/old-browser-popup.handlebars';

app.modules.oldBrowserPopup = ((self = {}) => {
  const USER_AGENT = navigator.userAgent;
  const SUPPORTED_BROWSERS = {
    chrome: 79,
    yabrowser: 17,
    firefox: 52,
    opr: 45,
    version: 10, //Safari
  };
  const UPDATE_LINKS = {
    chrome: 'https://www.google.ru/chrome/',
    yabrowser: 'https://browser.yandex.ru/',
    firefox: 'https://www.mozilla.org/ru/',
    opr: 'https://www.opera.com/ru',
    version: 'https://support.apple.com/ru-ru/HT204416', //Safari
  };

  function _initPopup(templateData) {
    const container = document.createElement('div');
    container.innerHTML = oldBrowserTemplate(templateData);
    app.modules.modalDialog.create({
      content: container,
      dialogClass: 'old-browser-popup',
      close: _setTimeLimit,
    });
  }

  function _createLink(browserName) {
    const updateLink = document.querySelector(
      '.js-old-browser-popup__link-download'
    );
    updateLink.href = UPDATE_LINKS[browserName];
  }

  function _checkBrowserVersion(browserName) {
    const currentBrowser = _getCurrentBrowserInfo(browserName);
    for (let browser in SUPPORTED_BROWSERS) {
      if (
        currentBrowser[browser] &&
        currentBrowser[browser] < SUPPORTED_BROWSERS[browser]
      ) {
        _initPopup({ie: false});
        _createLink(browserName.toLowerCase());
      }
    }
  }

  function _setTimeLimit() {
    const timeLimitData = {
      date: +new Date() + 3600 * 24 * 30,
    };
    IStorage.set('time-limit-blizko', JSON.stringify(timeLimitData));
  }

  function _getCurrentBrowserInfo(browserName) {
    const currentBrowser = {};
    USER_AGENT.split(' ').forEach((item) =>
      item.includes(browserName)
        ? (currentBrowser[browserName.toLowerCase()] = parseInt(
            item.match(/\d+/)
          ))
        : ''
    );
    return currentBrowser;
  }

  function _checkBrowserName() {
    const browserName = _getBrowserName();
    if (browserName === 'Trident') {
      _initPopup({ie: true, links: UPDATE_LINKS});
      return;
    } else if (
      SUPPORTED_BROWSERS[browserName.toLowerCase()] &&
      browserName !== 'Trident'
    ) {
      _checkBrowserVersion(browserName);
    }
  }

  function _getBrowserName() {
    if (USER_AGENT.indexOf('Firefox') > -1) {
      return 'Firefox';
    } else if (USER_AGENT.indexOf('OPR') > -1) {
      return 'OPR';
    } else if (USER_AGENT.indexOf('YaBrowser') > -1) {
      return 'YaBrowser';
    } else if (USER_AGENT.indexOf('Trident') > -1) {
      return 'Trident';
    } else if (USER_AGENT.indexOf('Chrome') > -1) {
      return 'Chrome';
    } else if (USER_AGENT.indexOf('Version') > -1) {
      //Safari
      return 'Version';
    }
  }

  function _checkTimeLimit() {
    IStorage.get('time-limit-blizko', function (data) {
      const currentDate = +new Date();
      const timeLimit = JSON.parse(data);
      if (timeLimit && currentDate < timeLimit.date) {
        return;
      }
      _checkBrowserName();
    });
  }

  function _init() {
    _checkTimeLimit();
  }

  self.load = _init;
  return self;
})(app.modules.oldBrowserPopup);
