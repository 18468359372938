import './style.scss';
import Root from './root.vue';
import '../../../vuex_modules/favorite_products';

function mountComponent(el) {
  if (!el) return;

  const {url, additionalClass, isPortalToolbar} = el.dataset;
  new Vue({
    el,
    store: vuexStore,
    render: (h) =>
      h(Root, {
        props: {
          url: url,
          additionalClass: additionalClass,
          defaultEmptyCount: isPortalToolbar ? '' : '0',
        },
      }),
  });
}

function handleToolbarLoad() {
  mountComponent(document.querySelector('.vue-favorite-products-counter'));
}

const element = document.querySelector('.vue-favorite-products-counter');

if (element) {
  mountComponent(element);
} else {
  document.addEventListener('toolbar:load', handleToolbarLoad);
}
