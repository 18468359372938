// Компонент попапа онлайн оплаты
import {LOCALES} from '../../src/cart/constants/common';
import store from '../../src/cart/store';
import _get from 'lodash.get';
import {getPayKeeperCart} from '@apress/apress-frontend-utils/utils/get-pay-keeper-cart/get-pay-keeper-cart';

const CLASS_NAMES = {
    popupClass: 'pay-keeper-popup',
  },
  SELECTORS = {
    popup: '.ui-dialog-content',
    form: '.js-pay-keeper-popup__form',
    formBtn: '.js-pay-keeper-popup__form-submit',
    phoneInput: '.js-pay-keeper-popup-phone-input',
  };
let $dialog;

function onSubmitBtnCLick(evt) {
  evt.preventDefault();
  const form = document.querySelector(SELECTORS.form);
  if (!form.checkValidity()) {
    form.reportValidity();
    return;
  }

  form.submit();
  setTimeout(function () {
    $dialog.dialog('close');
  }, 500);
}

function addListeners() {
  $doc.on('click', SELECTORS.formBtn, onSubmitBtnCLick);
}

const getDataForRender = (order) => ({
  formatted_total_price: order['formatted_total_with_discount_and_delivery'],
  total_price: parseFloat(order['items_total_price']['exact_value']),
  order_id: order.id,
  paykeeper_url: _get(order, 'company.order_settings.payments.paykeeper.url'),
  company_name: _get(order, 'company.name'),
  customer_name: _get(order, 'user.profile.name'),
  customer_email: _get(order, 'user.email'),
  customer_phone:
    order['customer_phone'] || _get(order, 'user.profile.contacts'),
  cart: getPayKeeperCart({
    vat: _get(order, 'company.vat'),
    items: order.items,
    delivery: order.delivery,
  }),
});

function reloadIfNotDurableOnLoad() {
  !app.config.isUserDurableOnLoad && window.location.reload();
}

function renderPayKeeperPopup(order, onAfterSuccess) {
  const data = getDataForRender(order);
  const template = store.getPayKeeperTemplates().payKeeperPopup;
  $dialog = $(template({data})).dialog({
    resizable: false,
    modal: true,
    draggable: false,
    dialogClass: CLASS_NAMES.popupClass,
    close: function () {
      $dialog.remove();
      if (onAfterSuccess) {
        return onAfterSuccess(reloadIfNotDurableOnLoad);
      }
      reloadIfNotDurableOnLoad();
    },
  });
  // Потому что через title в JQDialog не передать заголовок, содержащий html-разметку.
  $dialog
    .siblings()
    .find('.ui-dialog-title')
    .html(LOCALES.payKeeperPopup.title);

  $(SELECTORS.phoneInput).trigger('init:phoneMask').trigger('input');

  addListeners();
}

export default renderPayKeeperPopup;
