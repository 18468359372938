import api from '../constants/api';

function sendOrderItem({orderId, orderData}) {
  return fetch(api.getPlaceCartURL(orderId), {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    method: 'POST',
    body: JSON.stringify(orderData),
  })
    .then((res) => res.json())
    .then((response) => response);
}

export default sendOrderItem;
