import cartItem from '../components/orders-cart-item';
import {selectCartItemById} from '../utils/select-cart-item';
import {LOADER_SCOPE_NAME} from '../constants/common';
import store from '../store';
import addGetParamsToUrl from '../utils/add-get-params-to-url';
import api from '../constants/api';
import partOrder from '../components/part-order';
import {projectModules} from '../init-cart';
import {PROMO_CODE_SELECTORS} from '../constants/selectors';

function getCartItemsData({orderId, params = {}, mount} = {}) {
  const $component = selectCartItemById(orderId);

  const selectedItemsIds = partOrder.getSelectedOrderItemsIds($component);
  const additionalParams = Object.assign(
    {},
    store.getStoreDataByScopeName(LOADER_SCOPE_NAME),
    {'selected_item_ids[]': selectedItemsIds},
    params
  );
  const url = addGetParamsToUrl(
    api.getCartOrdersURL(orderId),
    additionalParams
  );

  return fetch(url)
    .then((res) => res.json())
    .then((response) => {
      const {order, errors} = response;
      const hideTotal = !app.config.isMobile && selectedItemsIds.length === 0;

      if (
        projectModules.promoCode &&
        $component.find(PROMO_CODE_SELECTORS.block).length
      ) {
        projectModules.promoCode.handlePromoCode(
          $component,
          response,
          hideTotal
        );
      }

      if (mount || (!order && errors)) {
        return response;
      }

      cartItem.updateTotal(order, hideTotal);
    });
}

export default getCartItemsData;
