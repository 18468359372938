// Функция получения полей АК и контактного телефона если тип комуникации с компанией "multiple".

/**
 * @see docs/frontend/common/communication.md
 */
export const getFieldsForMultiple = (order, multipleFieldType) => {
  const {user, delivery} = order;
  const auth = {
    auth: {
      set: ['multiple', 'name'],
      selfCleared: ['multiple'],
      required: ['multiple', 'name'],
      withMultipleField: true,
    },
  };

  const contacts = {
    contacts: true,
    contactsRequired: !!delivery,
  };

  if (!multipleFieldType || multipleFieldType === 'phone') {
    return user.phone || user.email ? contacts : auth;
  }

  return Object.assign(contacts, auth);
};
