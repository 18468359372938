export const ORDERS_CART_ITEMS_PER_PAGE = 15;
export const LOCALES = app.i18n;
export const ORDERS_CART_SCOPE_NAME = 'ordersCart';
export const EMPTY_ORDERS_CART_SCOPE_NAME = 'ordersCartEmpty';
export const LOADER_SCOPE_NAME = 'loader';
export const CART_ITEM_DATA_SCOPE_NAME = 'ordersCartItem';
export const CART_DATA_SCOPE_NAME = 'ordersCartData';
export const ADDITIONAL_INFO_DATA_SCOPE_NAME = 'additionalInfo';
export const SELECTRIC_OPTIONS_SCOPE_NAME = 'selectricOptions';
export const ERRORS_MESSAGES_SCOPE_NAME = '${errorType}DataMessage';
export const SPINNER_OPTIONS_SCOPE_NAME = 'spinnerOptions';
export const ORDERS_SHOW_CART_SCOPE_NAME = 'ordersShowCartTip';
export const CONTRACT_CURRENCY_ID = 11;
